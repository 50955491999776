import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoDataFound from "./NoDataFound";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useDeleteBannerAdsMutation,
  useUsersBannerAdsListQuery,
} from "../../../../Redux/Auth/bannerAds";
import { debounce, swlDetete } from "../../../../helper";
import LazyImageWithFallback from "../../../CommonComponents/Js/LazyImageWithFallback";
import Pagination from "../../../CommonComponents/Js/Pagination";
import Skeleton from "../../../CommonComponents/Js/Skeleton";
import Tooltips from "../../../CommonComponents/Js/Tooltips";
import StripPayment from "../Payment/Index";
import CommonModal from "../Payment/PaymentModal";
import DescriptionModal from "../../Home/descriptionModal";
import HelmetTitle from "../../../CommonComponents/Js/helmetTitle";
import { TabsContext } from "../Index";

const Advert = () => {
  const navigate = useNavigate();
  const { authTabs, dispatchAuthTabs } = useContext(TabsContext);
  // const [typeTab, setTypeTab] = useState("live");
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({
    page: 1,
    status: authTabs?.advert,
  });
  const [bannerId, setBannerId] = useState("");
  const location = useLocation();
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [advertModalData, setAdvertModalData] = useState({});
  const {
    data: bannerAds,
    isSuccess,
    isLoading,
    refetch,
  } = useUsersBannerAdsListQuery(filterData);
  const [removeAdvert] = useDeleteBannerAdsMutation();

  async function deleteAdvert(id) {
    let confirm = await swlDetete("You won't be able to revert this!");
    if (confirm) {
      let res = await removeAdvert(id);
      if (res?.data) {
        Swal.fire("Deleted!", "Advert deleted successfully", "success");
        refetch();
      } else {
        toast.error(res?.error?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }
  useEffect(() => {
    if (location?.state?.tab !== authTabs?.advert &&location?.state?.tab) {
      dispatchAuthTabs({ type: "advert", advert: location?.state?.tab });
      setFilterData({
        page: 1,
        status: location?.state?.tab,
      });
    }
  }, [location?.state?.tab]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);
  useEffect(() => {
    if (bannerAds && isSuccess) {
      if (!bannerAds?.data?.banners?.length && filterData?.page > 1) {
        setFilterData({ ...filterData, page: filterData?.page - 1 });
      }
    }
  }, [bannerAds]);
  // function tabChange(type) {
  //   setFilterData({
  //     page: 1,
  //     category: type,
  //   });
  //   // setTypeTab(type);
  // }
  const delayedSearch = useMemo(
    () =>
      debounce((value) => {
        // setSearchLoading(false);
        setFilterData((data) => {
          return {
            ...data,
            search: value,
            page: 1,
          };
        });
        // setShowLoading(false);
      }, 600),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  return (
    <>
      <HelmetTitle title="My Adverts - Discover Equestrian" />
      {isLoading && (
        <div className="col-12 mb-4">
          <Skeleton counter={4} height={220} />
        </div>
      )}

      <>
        <div className="commanCard-afterlogin">
          <div className="commanheading-afterlogin wishlist-txt">
            <div
              className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-4"
              id="advertsTop"
            >
              <div className="">My Adverts</div>
              <div className="sorting-details mb-0">
                <ul
                  className="nav nav-tabs border-0 flex-nowrap"
                  id="blog_tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <button
                      className={`nav-link ${authTabs?.advert === "live" && "active"}`}
                      onClick={() => {
                        if (authTabs?.advert !== "live") {
                          dispatchAuthTabs({ type: "advert", advert: "live" });
                          setFilterData({
                            page: 1,
                            status: "live",
                          });
                          setSearch("");
                        }
                      }}
                    >
                      Live
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${authTabs?.advert === "draft" && "active"}`}
                      onClick={() => {
                        if (authTabs?.advert !== "draft") {
                          dispatchAuthTabs({ type: "advert", advert: "draft" });
                          setFilterData({
                            page: 1,
                            status: "draft",
                          });
                          setSearch("");
                        }
                      }}
                    >
                      Draft
                    </button>
                  </li>
                </ul>
                {/* <div className="commaninput"> */}
                {/* </div> */}
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6 mb-3 mb-md-0">
                <div className="news-searchBar-wrapper">
                  <input
                    className="form-control search-input"
                    placeholder="Search here..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      delayedSearch(e.target.value);
                      // setShowLoading(true);
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-md-end">
                {isSuccess && bannerAds?.data?.adverts?.length !== 0 && (
                  <button
                    type="submit"
                    className="btn btn-icon-start btn-icon btn-yellow btncomman-afterlogin"
                    onClick={() =>
                      navigate("/advert/add", { state: filterData?.category })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 5V19"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Create Advert
                  </button>
                )}
              </div>
            </div>
          </div>
          {isSuccess && bannerAds?.data?.banners?.length === 0 ? (
            <NoDataFound />
          ) : (
            <>
              <div className="h-100">
                <div className="">
                  <div className="row">
                    {isSuccess && (
                      <>
                        {bannerAds?.data?.banners?.map((advert, ind) => (
                          <div className="col-12 mb-4" key={ind}>
                            <div className="advertise_card position-relative flex-column flex-md-row">
                              <div className="properties-profile mb-4 mb-md-0">
                                <LazyImageWithFallback
                                  src={advert?.image?.url}
                                  alt="properties logo"
                                />
                              </div>
                              <div className="advertise-right-wrapper">
                                <div className="d-flex flex-wrap-reverse flex-md-nowrap justify-content-between align-items-center gap-2">
                                  <h3 className="advertise-title">
                                    {advert?.name}
                                  </h3>
                                  <div className="buildings-info info-box d-flex">
                                    {!advert?.payment &&
                                      !advert?.isLive &&
                                      !bannerAds?.data?.paymentExempt && (
                                        <Tooltips msg={`Payment`}>
                                          <button
                                            className="btn rounded-circle btn-payment"
                                            onClick={() => {
                                              setPaymentModalShow(true);
                                              setBannerId(advert?._id);
                                            }}
                                          >
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 25 25"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M6 21.136V18.4H7.776V14.272H6.096V11.824H7.776V9.28C7.776 7.616 8.264 6.32 9.24 5.392C10.232 4.464 11.568 4 13.248 4C14.928 4 16.256 4.464 17.232 5.392C18.224 6.32 18.72 7.616 18.72 9.28V10.768H15.696V9.184C15.696 8.416 15.472 7.816 15.024 7.384C14.592 6.952 14 6.736 13.248 6.736C12.512 6.736 11.92 6.952 11.472 7.384C11.024 7.816 10.8 8.416 10.8 9.184V11.824H13.2V14.272H10.8V18.4H18.816V21.136H6Z"
                                                fill="#fff"
                                              />
                                            </svg>
                                          </button>
                                        </Tooltips>
                                      )}
                                    <Tooltips msg={`Edit`}>
                                      <button
                                        className="btn btn-edit"
                                        onClick={() =>
                                          navigate(
                                            "/advert/edit/" + advert?._id
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M8 13.333H14"
                                            stroke="#2E252D"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M11 2.33316C11.2652 2.06794 11.6249 1.91895 12 1.91895C12.1857 1.91895 12.3696 1.95553 12.5412 2.0266C12.7128 2.09767 12.8687 2.20184 13 2.33316C13.1313 2.46448 13.2355 2.62038 13.3066 2.79196C13.3776 2.96354 13.4142 3.14744 13.4142 3.33316C13.4142 3.51888 13.3776 3.70277 13.3066 3.87436C13.2355 4.04594 13.1313 4.20184 13 4.33316L4.66667 12.6665L2 13.3332L2.66667 10.6665L11 2.33316Z"
                                            stroke="#2E252D"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </Tooltips>
                                    <Tooltips msg={`Delete`}>
                                      <button
                                        className="btn btn-delete"
                                        onClick={() =>
                                          deleteAdvert(advert?._id)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M2 4H3.33333H14"
                                            stroke="#EB1E37"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M12.6663 4.00065V13.334C12.6663 13.6876 12.5259 14.0267 12.2758 14.2768C12.0258 14.5268 11.6866 14.6673 11.333 14.6673H4.66634C4.31272 14.6673 3.97358 14.5268 3.72353 14.2768C3.47348 14.0267 3.33301 13.6876 3.33301 13.334V4.00065M5.33301 4.00065V2.66732C5.33301 2.3137 5.47348 1.97456 5.72353 1.72451C5.97358 1.47446 6.31272 1.33398 6.66634 1.33398H9.33301C9.68663 1.33398 10.0258 1.47446 10.2758 1.72451C10.5259 1.97456 10.6663 2.3137 10.6663 2.66732V4.00065"
                                            stroke="#EB1E37"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6.66699 7.33398V11.334"
                                            stroke="#EB1E37"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9.33301 7.33398V11.334"
                                            stroke="#EB1E37"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </Tooltips>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-center locationView">
                                  <p className="advertisement-desc m-1">
                                    {stripHtml(advert?.description).substring(
                                      0,
                                      79
                                    )}
                                  </p>
                                  {stripHtml(advert?.description)?.length >=
                                    80 && (
                                    <button
                                      style={{ marginTop: "10px" }}
                                      onClick={() => {
                                        setShowDescriptionModal(true);
                                        setAdvertModalData({
                                          name: advert?.name,
                                          description: advert?.description,
                                          link: advert?.website,
                                        });
                                      }}
                                      className="btn btn-readMore border-0 px-0"
                                    >
                                      Read More
                                    </button>
                                  )}
                                </div>
                                <div>
                                  <p className="website">
                                    <Link
                                      className="btn"
                                      target="_blank"
                                      to={advert?.website}
                                    >
                                      <svg
                                        width="18"
                                        height="18"
                                        className="me-2"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clipPath="url(#clip0_2944_14948)">
                                          <path
                                            d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
                                            stroke="#0d6efd"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M1.66602 10H18.3327"
                                            stroke="#0d6efd"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9.99935 1.66699C12.0837 3.94895 13.2683 6.91035 13.3327 10.0003C13.2683 13.0903 12.0837 16.0517 9.99935 18.3337C7.91495 16.0517 6.73039 13.0903 6.66602 10.0003C6.73039 6.91035 7.91495 3.94895 9.99935 1.66699Z"
                                            stroke="#0d6efd"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_2944_14948">
                                            <rect
                                              width="20"
                                              height="20"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>{" "}
                                      Discover More
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {isSuccess && (
                <Pagination
                  pages={{
                    data: bannerAds?.data?.banners,
                    total: bannerAds?.data?.pageInfo?.totalPages, // Total number of cards
                    current: bannerAds?.data?.pageInfo?.page, // Current page count cards
                    filter: filterData, // Filter data to be passed to the API for fetching the data
                    setFilter: setFilterData, // Function to update the filter data when pagination is used
                  }}
                />
              )}
            </>
          )}
        </div>
        {bannerId && (
          <CommonModal
            type={"payment-modal"}
            show={paymentModalShow}
            onHide={() => setPaymentModalShow(false)}
          >
            <StripPayment
              bannerId={bannerId}
              setModalShow={setPaymentModalShow}
              refetch={refetch}
              setFilterData={setFilterData}
            />
          </CommonModal>
        )}
        <CommonModal
          type={"description-modal"}
          show={showDescriptionModal}
          onHide={() => setShowDescriptionModal(false)}
        >
          <DescriptionModal
            advertModalData={advertModalData}
            setShowDescriptionModal={setShowDescriptionModal}
          />
        </CommonModal>
      </>
    </>
  );
};

export default Advert;
