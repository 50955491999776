import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../Assets/images/logo.png"; //logo Image
import ComingSoonModal from "../../CommonComponents/Js/ComingSoonModal";
const NavbarAuth = ({ showSideBar }) => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const bodyRef = useRef(document.body);
  const [navbarShow, setNavbarShow] = useState(false);

  useEffect(() => {
    if (navbarShow) {
      bodyRef.current.classList.add("overflow-hidden");
    } else {
      bodyRef.current.classList.remove("overflow-hidden");
    }
    if (showSideBar) {
      setNavbarShow(false);
    }
  }, [navbarShow, showSideBar]);
  return (
    <>
      <ComingSoonModal show={show} setShow={setShow} />
      <main className="afterlogin-header">
        <header className="header py-3">
          <nav className="navbar navbar-expand-xl navbar-dark">
            <div className="d-flex flex-wrap flex-md-nowrap justify-content-between w-100 logotxt-wrapper">
              <Link
                to="/"
                className="d-flex align-items-center me-lg-auto text-decoration-none"
              >
                <img className="logo img-fluid" src={logo} alt="logo" />
                <span>
                  Discover
                  <br />
                  Equestrian
                </span>
              </Link>
              <button
                className="navbar-toggler"
                onClick={(e) => setNavbarShow(!navbarShow)}
                type="button"
                // data-bs-toggle="collapse"
                // data-bs-target="#myNavbar"
                // aria-controls="myNavbar"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse justify-content-end ${
                  navbarShow ? "show" : ""
                }`}
                id="myNavbar"
              >
                <ul id="navbarNav" className="nav nav-pills">
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        (pathname === "/" || pathname === "/home") && "active"
                      }`}
                      onClick={() => setNavbarShow(false)}
                      aria-current="page"
                      to="/home"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        pathname === "/about" && "active"
                      }`}
                      to="/about"
                      onClick={() => setNavbarShow(false)}
                    >
                      About us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        pathname === "/properties" && "active"
                      }`}
                      to="/properties"
                      onClick={() => setNavbarShow(false)}
                    >
                      Properties
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        pathname === "/riding" && "active"
                      }`}
                      to="/riding"
                      onClick={() => setNavbarShow(false)}
                    >
                      Riding
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        pathname === "/shopping" && "active"
                      }`}
                      to="/shopping"
                      // to="#"
                      // onClick={() => setShow(true)}
                      onClick={() => setNavbarShow(false)}
                    >
                      Shopping
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        pathname === "/businesses-services" && "active"
                      }`}
                      to="/businesses-services"
                      onClick={() => setNavbarShow(false)}
                    >
                      Businesses & Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        pathname === "/live-recordings" && "active"
                      }`}
                      to="#"
                      onClick={() => setShow(true)}
                    >
                      Live Tv & Recordings
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/faq" && "active"}`}
                      to="/faq"
                      onClick={() => setNavbarShow(false)}
                    >
                      FAQ's
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        pathname === "/news-blog" && "active"
                      }`}
                      onClick={() => setNavbarShow(false)}
                      to="/news-blog"
                    >
                      News & Blogs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </main>
    </>
  );
};

export default NavbarAuth;
