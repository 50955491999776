import React from "react";
import { Helmet } from "react-helmet";
const HelmetTitle = ({ title="", description = false, tags = [] }) => {
    return (
        <Helmet>
            <title>{title ? title : 'Discover Equestrian'}</title>
            {description && <meta name="description" content={description || "Discover Equestrian"} />}
            {tags?.length && <meta name="keywords" content={tags?.join(", ") || "Discover Equestrian"} />}
        </Helmet>
    );
};

export default HelmetTitle;
