import React from "react";
import { HashLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="loading-conainter">
      <HashLoader color="#fff" size={120} />
    </div>
  );
};

export default Loading;
