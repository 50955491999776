import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the chat slice
const initialState = {
  getpropertyList: {
    propertyList: [],
    page: 1,
  },
  socketModule: null,
  selectProperty: [],
  getProperty: "",
  chatPages: 1,
  getNofication: {
    count: 0,
    notifications: [],
    page: 1,
  },
  selectPropertyDetails: {},
  propertyExist: null,
};
// Create the chat slice using createSlice
export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    // Reducer for updating the Property List
    propertyLists: (state, { payload }) => {
      const { data, listParams } = payload;
      if (data) {
        if (listParams?.page > 1) {
          state.getpropertyList.propertyList = [
            ...data?.List,
            ...state.getpropertyList.propertyList,
          ];
          state.getpropertyList.page = data?.pageInfo?.totalPages;
        } else {
          if (listParams?.search || listParams?.search === "") {
            state.getpropertyList.propertyList = data?.List;
            state.getpropertyList.page = data?.pageInfo?.totalPages;
          } else if (state.getpropertyList.propertyList?.length) {
            state.getpropertyList.propertyList = [
              ...state.getpropertyList.propertyList,
              ...data?.List,
            ];
          } else {
            state.getpropertyList.propertyList = data?.List;
            state.getpropertyList.page = data?.pageInfo?.totalPages;
          }
        }
      }
    },
    addPropertyLists: (state, { payload }) => {
      if (payload?.flag) {
        state.getpropertyList.propertyList = payload?.data?.List;
        state.getpropertyList.page = payload?.data?.pageInfo?.totalPages;
      } else if (payload[0]?.propertyId) {
        state.getpropertyList.propertyList = [
          ...payload,
          ...state?.getpropertyList.propertyList,
        ];
      }
    },
    updatePropertyLists: (state, { payload }) => {
      if (payload) {
        state.getpropertyList.propertyList = payload?.List;
        state.getpropertyList.page = payload?.pageInfo?.totalPages;
      }
    },
    // Reducer for updating Slected Property information
    propertyChat: (state, { payload }) => {
      state.propertyExist = payload?.propertyExist;
      if (payload?.pageInfo?.page === 1 && payload?.chats?.length) {
        state.selectProperty = Array.from(payload?.chats).reverse();
        state.chatPages = payload?.pageInfo?.totalPages;
      } else if (payload?.chats?.length) {
        state.selectProperty = [
          ...Array.from(payload?.chats).reverse(),
          ...state.selectProperty,
        ];
        state.chatPages = payload?.pageInfo?.totalPages;
      }
    },
    addNewChat: (state, { payload }) => {
      state.selectProperty = [];
    },
    addChat: (state, { payload }) => {
      if (payload["message"]) {
        state.selectProperty = [
          ...state?.selectProperty,
          { ...payload["message"], unReadCount: payload["unReadCount"] },
        ];
      } else {
        state.selectProperty = [...state?.selectProperty, ...payload];
      }
    },
    addSocketModule: (state, { payload }) => {
      if (payload) {
        state.socketModule = payload;
      }
    },
    notificationChat: (state, { payload }) => {
      if (payload?.notifications?.length) {
        if (payload?.flag) {
          state.getNofication.count = payload?.count;
          state.getNofication.notifications = payload?.notifications?.filter(
            (person, index) =>
              index ===
              payload?.notifications?.findIndex(
                (other) => person?._id === other?._id
              )
          );
          state.getNofication.page = payload?.pageInfo?.totalPages;
        } else {
          state.getNofication.count = payload?.count;
          state.getNofication.notifications = [
            ...state.getNofication.notifications,
            ...payload?.notifications,
          ]?.filter(
            (person, index) =>
              index ===
              [
                ...state.getNofication.notifications,
                ...payload?.notifications,
              ]?.findIndex((other) => person?._id === other?._id)
          );
          state.getNofication.page = payload?.pageInfo?.totalPages;
        }
      } else {
        state.getNofication.count = payload?.count;
        state.getNofication.notifications = [];
        state.getNofication.page = 1;
      }
    },
    addNotification: (state, { payload }) => {
      if (payload) {
        state.getNofication.count = state.getNofication.count + 1;
        state.getNofication.notifications = [
          payload,
          ...state?.getNofication.notifications,
        ]?.filter(
          (person, index) =>
            index ===
            [payload, ...state?.getNofication.notifications]?.findIndex(
              (other) => person?._id === other?._id
            )
        );
      }
    },
    addSelectedPropertyDetails: (state, { payload }) => {
      state.selectPropertyDetails = payload;
    },
    checkDeleteProperty: (state, { payload }) => {
      state.propertyExist = payload?.data?.propertyExist;
    },
  },
});

// Action creators are automatically generated for each case reducer function
// Export the action creators
export const {
  propertyLists,
  addPropertyLists,
  updatePropertyLists,
  propertyChat,
  addNewChat,
  addChat,
  addSocketModule,
  notificationChat,
  addNotification,
  addSelectedPropertyDetails,
  checkDeleteProperty,
} = chatSlice.actions;

// Export the chat slice reducer
export default chatSlice.reducer;
