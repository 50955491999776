// query and mutations for AUth user APIs
import { config } from "../config";
import { formConfig } from "../config";
import { serverApi } from "../serviceAPI";

const propertyQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    propertys: builder.query({
      query: (payload) => {
        const params = new URLSearchParams();
        params.set("limit", "10");
        params.set("page", payload.page || "1"); // Set a default page value if not provided

        if (payload?.type) {
          params.set("type", payload.type);
        }

        if (payload?.search) {
          params.set("search", payload.search);
        }

        if (payload?.sortBy) {
          params.set("sortBy", payload.sortBy);
        }

        const url = `/Property/mine?${params.toString()}`;
        return config("Get", url, true);
      },
    }),
    property: builder.mutation({
      query: (payload) => {
        return config("GET", `/Property/${payload}`, true);
      },
    }),
    addProperty: builder.mutation({
      query: (payload) => {
        return formConfig("post", "property", payload);
      },
    }),
    deleteProperty: builder.mutation({
      query: (payload) => {
        return config(
          "PATCH",
          `/Property/${payload.id}/${payload?.archive}`,
          true
        );
      },
    }),
    permanentDeleteProperty: builder.mutation({
      query: (payload) => {
        return config(
          "DELETE",
          `/property/${payload.id}`,
          true
        );
      },
    }),
    updateProperty: builder.mutation({
      query: (payload) => {
        return formConfig("PATCH", `/Property/${payload?.id}`, payload?.data);
      },
    }),
    paymentProperty: builder.mutation({
      query: (payload) => {
        return formConfig("POST", `/payment/${payload?.propertyId ? "property" : payload?.bannerId ? "banners       " : "advert"}`, payload);
      },
    }),
    getCharges: builder.query({
      query: () => {
        return config("GET", `/settings/charges`, true);
      },
    }),
    getPropertyComment: builder.query({
      query: (payload) => {
        return config("GET", `/notes/comment/${payload}`, true);
      },
    }),
    reApproveProperty: builder.mutation({
      query: (payload) => {
        return formConfig("PATCH", `/property/review/${payload}`, payload);
      },
    }),
    saveSearchList: builder.mutation({
      query: (payload) => {
        return config("GET", `/property/save-search?page=${payload?.page ?? 1}&limit=${payload?.limit ?? 10}`, true);
      },
    }),
    deleteSearch: builder.mutation({
      query: (payload) => {
        return config("DELETE", `/property/save-search/${payload}`, true);
      },
    }),
    updateSearch: builder.mutation({
      query: (payload) => {
        return formConfig("PUT", `/property/save-search/${payload?.id}`, payload?.data);
      },
    }),
    singleSearch: builder.mutation({
      query: (payload) => {
        return config("GET", `/property/save-search/${payload}`, true);
      },
    }),
    addSaveSearch: builder.mutation({
      query: (payload) => {
        return formConfig("POST", `/property/save-search`, payload?.data);
      },
    })
  }),
  overrideExisting: false,
});

export const {
  usePropertysQuery,
  useAddPropertyMutation,
  usePropertyMutation,
  useDeletePropertyMutation,
  useUpdatePropertyMutation,
  usePaymentPropertyMutation,
  useGetChargesQuery,
  useGetPropertyCommentQuery,
  useReApprovePropertyMutation,
  useSaveSearchListMutation,
  useDeleteSearchMutation,
  useUpdateSearchMutation,
  useSingleSearchMutation,
  useAddSaveSearchMutation,
  usePermanentDeletePropertyMutation
} = propertyQuery;
