import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../Assets/images/logo.png"; //logo Image
import ComingSoonModal from "../../CommonComponents/Js/ComingSoonModal";
import HelmetTitle from "../../CommonComponents/Js/helmetTitle";
const Navbar = () => {
  const { pathname } = useLocation();
  let activePath =
    pathname.split("/")?.length > 0 ? "/" + pathname.split("/")[1] : pathname;
  const bodyRef = useRef(document.body);
  const [show, setShow] = useState(false);
  const [navbarShow, setNavbarShow] = useState(false);

  useEffect(() => {
    if (navbarShow) {
      bodyRef.current.classList.add("overflow-hidden");
    } else {
      bodyRef.current.classList.remove("overflow-hidden");
    }
  }, [navbarShow]);
  return (
    <>
      <HelmetTitle title="" />
      <ComingSoonModal show={show} setShow={setShow} />
      <header className="header py-3 mb-4">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container d-flex flex-wrap justify-content-between">
            <Link
              to="/"
              className="d-flex align-items-center me-lg-auto text-decoration-none"
            >
              <img className="logo img-fluid" src={logo} alt="logo" />
              {/* <span className="fs-4">Discover Equestrian</span> */}
            </Link>

            <button
              className={`navbar-toggler collapsed`}
              onClick={(e) => setNavbarShow(!navbarShow)}
              type="button"
            // data-bs-toggle="collapse"
            // data-bs-target="#myNavbar"
            // aria-controls="myNavbar"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse justify-content-end ${navbarShow ? "show" : ""
                }`}
              id="myNavbar"
            >
              <ul id="navbarNav" className="nav nav-pills">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${(pathname === "/" || activePath === "/home") && "active"
                      }`}
                    aria-current="page"
                    to="/home"
                    onClick={() => setNavbarShow(false)}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/about" && "active"
                      }`}
                    to="/about"
                    onClick={() => setNavbarShow(false)}
                  >
                    About us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/properties"
                      ? "active"
                      : ""
                      }`}
                    to="/properties"
                    onClick={() => setNavbarShow(false)}
                  >
                    Properties
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/riding" && "active"
                      }`}
                    to="/riding"
                    onClick={() => setNavbarShow(false)}
                  >
                    Riding
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/shopping" && "active"
                      }`}
                    to="/shopping"
                    // onClick={() => {
                    //   setShow(true);
                    //   bodyRef.current.classList.remove("overflow-hidden");
                    // }}
                    onClick={() => setNavbarShow(false)}
                  >
                    Shopping
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/businesses-services" && "active"
                      }`}
                    to="/businesses-services"
                    onClick={() => setNavbarShow(false)}
                  >
                    Businesses & Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/live-recordings" && "active"
                      }`}
                    to="#"
                    onClick={() => {
                      setShow(true);
                      bodyRef.current.classList.remove("overflow-hidden");
                    }}
                  >
                    Live Tv & Recordings
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/faq" && "active"}`}
                    to="/faq"
                    onClick={() => setNavbarShow(false)}
                  >
                    FAQ's
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePath === "/news-blog" && "active"
                      }`}
                    to="/news-blog"
                    onClick={() => setNavbarShow(false)}
                  >
                    News & Blogs
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>

    // <nav className="navbar navbar-expand-lg navbar-light bg-light">
    //   <div className="container-fluid">
    //     <Link className="navbar-brand" href="#">Navbar</Link>
    //     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //       <span className="navbar-toggler-icon"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //       <ul className="navbar-nav me-auto mb-2 mb-lg-0">
    //         <li className="nav-item">
    //           <Link className="nav-link active" aria-current="page" href="#">Home</Link>
    //         </li>
    //         <li className="nav-item">
    //           <Link className="nav-link" href="#">Link</Link>
    //         </li>
    //         <li className="nav-item dropdown">
    //           <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    //             Dropdown
    //           </Link>
    //           <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
    //             <li><Link className="dropdown-item" href="#">Action</Link></li>
    //             <li><Link className="dropdown-item" href="#">Another action</Link></li>
    //             <li><hr className="dropdown-divider"/></li>
    //             <li><Link className="dropdown-item" href="#">Something else here</Link></li>
    //           </ul>
    //         </li>
    //         <li className="nav-item">
    //           <Link className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</Link>
    //         </li>
    //       </ul>
    //       <form className="d-flex">
    //         <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
    //         <button className="btn btn-outline-success" type="submit">Search</button>
    //       </form>
    //     </div>
    //   </div>
    // </nav>
  );
};

export default Navbar;
