import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import PaymentForm from "./paymentForm";
import closeBtn from "../../../../Assets/images/icon/close.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TabsContext } from "../Index";
const StripPayment = ({
  propertyId,
  setModalShow,
  bannerId,
  refetch,
  flag,
  res,
  advertId,
  invoicePayment,
  setFilterData,
  invoiceId,
  price,
  dataForm,
  rejectedFlag,
  setRejectModalShow,
  preApprovedUser,
  paymentExempt,
  countOfBd,
}) => {
  const { dispatchAuthTabs } = useContext(TabsContext);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const navigate = useNavigate();
  return (
    <>
      <Modal.Header className="flex-column">
        {/* Cancel button */}
        <div className="w-100 d-flex align-items-center justify-content-between">
          <h5 className="modal-title">
            Payment For{" "}
            {propertyId
              ? "Property"
              : bannerId
                ? "Adverts"
                : "Businesses & Services"}
          </h5>
          <Button
            className="modal-closeBtn"
            onClick={() => {
              if (rejectedFlag) {
                setModalShow(false);
                setRejectModalShow(true);
              } else {
                setModalShow(false);
                if (flag) {
                  toast.success(res, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  if (propertyId) {
                    if (preApprovedUser || paymentExempt) {
                      dispatchAuthTabs({
                        type: "property",
                        property: "live",
                      });
                    } else {
                      dispatchAuthTabs({
                        type: "property",
                        property: "payment-remaining",
                      });
                    }
                    navigate("/property");
                  } else if (bannerId) {
                    dispatchAuthTabs({
                      type: "advert",
                      advert: "draft",
                    });
                    navigate("/advert");

                  } else {
                    dispatchAuthTabs({
                      type: "businessAndService",
                      businessAndService: "draft",
                    });
                    navigate("/business-service");
                  }
                }
              }
            }}
            aria-label="Close"
          >
            <img src={closeBtn} alt="close" />
          </Button>
        </div>

        {/* {!propertyId && <div className="mt-3">
          <p className="profile-img-note mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              fill="currentColor"
              className="bi bi-exclamation-circle pb-1"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
            </svg>{" "}
            Your payment will be renewed every month on the specified billing date. Please ensure that your payment method is up-to-date to avoid any interruptions to your service.
          </p>
        </div>} */}
      </Modal.Header>
      <Modal.Body>
        <Elements stripe={stripePromise}>
          <PaymentForm
            bannerId={bannerId}
            propertyId={propertyId}
            invoicePayment={invoicePayment}
            advertId={advertId}
            price={price}
            invoiceId={invoiceId}
            setModalShow={setModalShow}
            setFilterData={setFilterData}
            refetch={refetch}
            flag={flag}
            resMessage={res}
            dataForm={dataForm}
            rejectedFlag={rejectedFlag}
            setRejectModalShow={setRejectModalShow}
            countOfBd={countOfBd}
          />
        </Elements>
      </Modal.Body>
    </>
  );
};

export default StripPayment;
