import React from "react";
import { Modal } from "react-bootstrap";

const CommonModal = (props) => {
  return (
    <Modal
      {...props}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={props?.scroll === false ? false : true}
      className={props?.type}
    >
      {/* Display the passed content using props.children */}
      {props.children}
    </Modal>
  );
};

export default CommonModal;
