import { config } from "../config";
import { serverApi } from "../serviceAPI";

const globalQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getPagesData: builder.query({
      query: (payload) => {
        return config("Get", `/pages/${payload}`);
      },
    }),
    contactUs: builder.mutation({
      query: (payload) => {
        return {
          ...config("POST", `/contact-us`, true),
          body: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetPagesDataQuery, useContactUsMutation } = globalQuery;
