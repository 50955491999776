import { toast } from "react-toastify";
import {
  MAX_IMAGE_SIZE,
  MAX_PROPERTY_IMAGE_SIZE,
  replaceQuillClasses,
} from "../../../../helper";

// Option data for selecting sorting options
export const searchObj = [
  { value: "", label: "Select Type" },
  { value: "sale", label: "Available for sale" },
  { value: "rent", label: "Available for rent" },
];

// Prefix options for property prices
export const Prefix = (data) => {
  let options = [];
  if (data?.prefixes) {
    options = data?.prefixes?.map((id) => ({ value: id?.id, label: id?.name }));
  }
  return options;
};

// Form validation schema using Yup
export const validationSchema = (Yup) => {
  return Yup.object().shape({
    type: Yup.string().required("Type selection is required"),
    price: Yup.number()
      .min(1, "Please enter valid price")
      .max(100000000000, "Price must not exceed €100,000,000,000")
      .integer("Please enter valid price")
      .required("Price is required"),
    title: Yup.string()
      .required("Title is required")
      .max(100, "Title should be less than or equal to  100 characters")
      .trim(),
    location: Yup.string()
      .required("Full Address is required")
      .test("not-same-as-old", "Please enter valid Address", function (value) {
        return typeof this.parent.lat == "number";
      }),
    beds: Yup.number()
      .min(0, "Negative values for beds are not allowed")
      .integer("Please enter valid beds counts")
      .max(99, "Beds counts should be less than or equal to 99"),
    // .required("Number of beds is required"),
    bathrooms: Yup.number()
      .min(0, "Negative values for bathrooms are not allowed")
      .integer("Please enter valid bathrooms counts")
      .max(99, "Bathrooms counts should be less than or equal to 99"),
    // .required("Number of bathrooms is required"),
    prefixId: Yup.string().required("Prefix selection is required"),
    area: Yup.number().required("Area (Acres) is required"),
    videoLink: Yup.string().matches(
      /* eslint-disable-next-line */
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      "Please enter valid video link"
    ),
    // .required("Video link URL is required"),
    description: Yup.string()
      // .min(100, "Description must be at least 100 characters")
      // .max(700, "Description can be at most 700 characters")
      .required("Description is required")
      .trim(),
    stable: Yup.boolean(),
    outBuilding: Yup.boolean(),
    manage: Yup.boolean(),
    annex: Yup.boolean(),
    autoUpdate: Yup.boolean(),
    images: Yup.array().min(1, "Image is required"),
    // images: Yup.mixed().required("Images is required"),
  });
};
export const validationSchemaForDraft = (Yup) => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });
};

// Initial values for the form
export const initialValues = (id, propertyData, userId) => {
  return {
    user: userId,
    type: id && propertyData?.isSuccess ? propertyData?.data?.data?.type : "",
    price: id && propertyData?.isSuccess ? propertyData?.data?.data?.price : "",
    title: id && propertyData?.isSuccess ? propertyData?.data?.data?.title : "",
    location:
      id && propertyData?.isSuccess ? propertyData?.data?.data?.address : "",
    long:
      id && propertyData?.isSuccess
        ? propertyData?.data?.data?.location?.coordinates[0]
        : "",
    addressMeta:
      id && propertyData?.isSuccess
        ? propertyData?.data?.data?.addressMeta
        : "",
    lat:
      id && propertyData?.isSuccess
        ? propertyData?.data?.data?.location?.coordinates[1]
        : "",
    beds: id && propertyData?.isSuccess ? propertyData?.data?.data?.beds : "",
    bathrooms:
      id && propertyData?.isSuccess ? propertyData?.data?.data?.bathrooms : "",
    area: id && propertyData?.isSuccess ? propertyData?.data?.data?.area : "",
    prefixId:
      id && propertyData?.isSuccess
        ? propertyData?.data?.data?.prefixId
        : propertyData?.data?.data?.prefixId,
    videoLink:
      id && propertyData?.isSuccess ? propertyData?.data?.data?.videoLink : "",
    description:
      id && propertyData?.isSuccess
        ? propertyData?.data?.data?.description
        : "",
    stable:
      id && propertyData?.isSuccess ? propertyData?.data?.data?.stable : false,
    outBuilding:
      id && propertyData?.isSuccess
        ? propertyData?.data?.data?.outBuilding
        : false,
    manage:
      id && propertyData?.isSuccess ? propertyData?.data?.data?.manage : false,
    annex:
      id && propertyData?.isSuccess ? propertyData?.data?.data?.annex : false,
    autoUpdate:
      id && propertyData?.isSuccess
        ? propertyData?.data?.data?.autoUpdate
        : false,
    images:
      id && propertyData?.isSuccess ? propertyData?.data?.data?.images : [],
    deleteImages: [],
    imageMap: [],
  };
};

export const submitValues = (values, id, propertyData) => {
  var data = new FormData();
  // data.append("user", values?.user);
  data.append("stable", `${values?.stable}`);
  if (propertyData?.data?.data?.status === "draft") {
    data.append("draft", false);
  }
  data.append("outBuilding", `${values?.outBuilding}`);
  data.append("manage", `${values?.manage}`);
  data.append("annex", `${values?.annex}`);
  if (values?.addressMeta?.county && !propertyData?.data?.data?.isLive) {
    data.append("addressMeta[county]", `${values?.addressMeta?.county}`);
  }
  if (values?.addressMeta?.town && !propertyData?.data?.data?.isLive) {
    data.append("addressMeta[town]", `${values?.addressMeta?.town}`);
  }
  data.append("autoUpdate", `${values?.autoUpdate}`);
  data.append("area", `${values?.area}`);
  data.append("prefixId", values?.prefixId);
  data.append("type", values?.type);
  data.append("title", values?.title);
  data.append("price", `${values?.price}`);
  if (values?.beds) {
    data.append("beds", `${values?.beds}`);
  } else {
    data.append("beds", `${0}`);
  }
  if (values?.bathrooms) {
    data.append("bathrooms", `${values?.bathrooms}`);
  } else {
    data.append("bathrooms", `${0}`);
  }
  if (values?.videoLink) {
    data.append("videoLink", values?.videoLink);
  } else {
    data.append("videoLink", "");
  }
  if (!propertyData?.data?.data?.isLive) {
    data.append("location[coordinates][0]", `${values?.long}`);
    data.append("location[coordinates][1]", `${values?.lat}`);
    data.append("address", values?.location);
  }
  data.append("description", replaceQuillClasses(`${values?.description}`));
  for (const file of values?.images) {
    if (file?.constructor === File) {
      data.append("images", file);
    }
  }
  if (id && values?.deleteImages.length > 0) {
    values?.deleteImages?.map((file, i) =>
      data.append(`deleteImages[${i}]`, `${file}`)
    );
  }
  if (id && values?.imageMap.length > 0) {
    values?.imageMap?.map((file, i) => {
      return file?.name
        ? data.append(`imageMap[${i}]`, `${file?.name}`)
        : data.append(`imageMap[${i}]`, `${file?.key}`);
    });
  }
  return data;
};
export const draftValues = (values, id, edit) => {
  var data = new FormData();
  // data.append("user", values?.user);
  data.append("stable", `${values?.stable}`);
  data.append("draft", true);
  data.append("outBuilding", `${values?.outBuilding}`);
  data.append("manage", `${values?.manage}`);
  data.append("annex", `${values?.annex}`);
  if (values?.addressMeta?.county) {
    data.append("addressMeta[county]", `${values?.addressMeta?.county}`);
  } else if (edit) {
    data.append("addressMeta[county]", "");
  }
  if (values?.addressMeta?.town) {
    data.append("addressMeta[town]", `${values?.addressMeta?.town}`);
  } else if (edit) {
    data.append("addressMeta[town]", `${values?.addressMeta?.town}`);
  }
  data.append("autoUpdate", `${values?.autoUpdate}`);
  if (values?.area) {
    data.append("area", `${values?.area}`);
  } else if (edit) {
    data.append("area", "");
  }
  if (values?.long) {
    data.append("location[coordinates][0]", `${values?.long}`);
  } else if (edit) {
    data.append("location[coordinates][0]", 0);
  }
  if (values?.lat) {
    data.append("location[coordinates][1]", `${values?.lat}`);
  } else if (edit) {
    data.append("location[coordinates][1]", 0);
  }
  if (values?.prefixId) {
    data.append("prefixId", values?.prefixId);
  } else if (edit) {
    data.append("prefixId", "");
  }
  if (values?.type) {
    data.append("type", values?.type);
  } else if (edit) {
    data.append("type", "");
  }
  if (values?.title) {
    data.append("title", values?.title);
  }
  if (values?.price) {
    data.append("price", `${values?.price}`);
  } else if (edit) {
    data.append("price", "");
  }
  if (values?.beds) {
    data.append("beds", `${values?.beds}`);
  } else {
    data.append("beds", `${0}`);
  }
  if (values?.bathrooms) {
    data.append("bathrooms", `${values?.bathrooms}`);
  } else {
    data.append("bathrooms", `${0}`);
  }
  if (values?.videoLink) {
    data.append("videoLink", values?.videoLink);
  } else {
    data.append("videoLink", "");
  }
  if (values?.location) {
    data.append("address", values?.location);
  } else if (edit) {
    data.append("address", "");
  }
  if (values?.description) {
    data.append("description", replaceQuillClasses(`${values?.description}`));
  } else if (edit) {
    data.append("description", "");
  }
  for (const file of values?.images) {
    if (file?.constructor === File) {
      data.append("images", file);
    }
  }
  if (id && values?.deleteImages.length > 0) {
    values?.deleteImages?.map((file, i) =>
      data.append(`deleteImages[${i}]`, `${file}`)
    );
  }
  if (id && values?.imageMap.length > 0) {
    values?.imageMap?.map((file, i) => {
      return file?.name
        ? data.append(`imageMap[${i}]`, `${file?.name}`)
        : data.append(`imageMap[${i}]`, `${file?.key}`);
    });
  }
  return data;
};

export const responseForPayment = (
  res,
  toast,
  navigate,
  setFilterData,
  dispatchAuthTabs,
  resMessage
) => {
  if (res?.data?.type === "success") {
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (res?.data?.data?.isLive) {
      setFilterData((data) => {
        return {
          ...data,
          type: "live",
        };
      });
      dispatchAuthTabs({
        type: "property",
        property: "live",
      });
    } else {
      setFilterData((data) => {
        return {
          ...data,
          type: "underReview",
        };
      });
      dispatchAuthTabs({
        type: "property",
        property: "underReview",
      });
    }
    navigate("/property");
  }
  if (resMessage) {
    toast.success(resMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setFilterData((data) => {
      return {
        ...data,
        type: "underReview",
      };
    });
    dispatchAuthTabs({
      type: "property",
      property: "underReview",
    });
    navigate("/property");
  }
  if (res?.error) {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// Handle response from the server after form submission
export const response = (
  res,
  toast,
  navigate,
  dispatchAuthTabs,
  resMessage
) => {
  if (res?.data?.type === "success") {
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (res?.data?.data?.isLive) {
      dispatchAuthTabs({
        type: "property",
        property: "live",
      });
    } else if (res?.data?.data?.status === "paymentRemaining") {
      dispatchAuthTabs({
        type: "property",
        property: "payment-remaining",
      });
    } else {
      dispatchAuthTabs({
        type: "property",
        property: "underReview",
      });
    }
    navigate("/property");
  }
  if (resMessage) {
    toast.success(resMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatchAuthTabs({
      type: "property",
      property: "underReview",
    });
    navigate("/property");
  }
  if (res?.error) {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const responseDraft = (res, toast, navigate, dispatchAuthTabs) => {
  if (res?.data?.type === "success") {
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatchAuthTabs({
      type: "property",
      property: "draft",
    });
    navigate("/property");
  }
  if (res?.error) {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
// Function to remove an image from the list
export const handleRemoveImage = (index, formik, img) => {
  const newImgData = [...formik.values.images];
  newImgData.splice(index, 1);
  formik.setFieldValue("images", newImgData);
  formik.setFieldValue("imageMap", newImgData);
  formik.setFieldValue("deleteImages", [
    ...formik.values.deleteImages,
    img.key,
  ]);
};

// Radio button input options
export const radioInputs = [
  {
    label: "Stables",
    name: "stable",
  },
  {
    label: "Outbuilding(s)",
    name: "outBuilding",
  },
  {
    label: "Manège",
    name: "manage",
  },
  {
    label: "Annex",
    name: "annex",
  },
  {
    label: "Autoupdate",
    name: "autoUpdate",
  },
];

export const handleOpenImageCrop = (
  files,
  setInvalidImage,
  setFile,
  setModalShow,
  propertyImage
) => {
  // Check if files exist and contain items
  if (!files || !files.length) return;

  // Arrays to store valid and invalid image files
  const validFiles = [];
  const invalidFiles = [];

  // Iterate through each file
  [...files].forEach((img) => {
    // Check if the image size is within the specified limits
    if (
      img.size <=
      (propertyImage
        ? MAX_PROPERTY_IMAGE_SIZE * 1024 * 1024
        : MAX_IMAGE_SIZE * 1024 * 1024)
    ) {
      validFiles.push(img);
    } else {
      invalidFiles.push(img);
    }
  });
  // If no valid files found, show a toast notification and exit
  if (validFiles?.length === 0) {
    toast.info(
      `The image should not exceed ${propertyImage ? MAX_PROPERTY_IMAGE_SIZE : MAX_IMAGE_SIZE} MB in size.`,
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
    return;
  }
  // Update the count of invalid images
  setInvalidImage(invalidFiles?.length);
  // Set the valid image files
  setFile([...validFiles]);
  // Display the modal for cropping the valid images
  setModalShow(true);
};
