import React, { createContext, useEffect, useReducer, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import close from "../../../Assets/images/icon/close.svg";
import { handleLogout } from "../../../Redux/Auth/users";
import Footer from "../../Layouts/Js/Footer";
import HeaderAuth from "../../Layouts/Js/HeaderAuth";
import NavbarAuth from "../../Layouts/Js/NavbarAuth";
import LogoutModal from "./LogoutModal";
export const Chat = createContext();
export const TabsContext = createContext(null);
function reducer(state, action) {
  switch (action?.type) {
    case "property":
      return {
        ...state,
        property: action?.property,
      };
    case "businessAndService":
      return {
        ...state,
        businessAndService: action?.businessAndService,
      };
    case "advert":
      return {
        ...state,
        advert: action?.advert,
      };
    case "invoice":
      return {
        ...state,
        invoice: action?.invoice,
      };
    default:
      return state;
  }
}

const AuthLayout = ({ children }) => {
  const { pathname } = useLocation();
  const [openNotify, setOpenNotify] = useState(false);
  const [showSideBar, setshowSideBar] = useState(false);
  const [authTabs, dispatchAuthTabs] = useReducer(reducer, {
    property: "live",
    businessAndService: "live",
    advert: "live",
    invoice: "property",
  });

  const newRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (
      e?.target?.parentElement?.id !== "notification" ||
      e?.target?.id !== "notification"
    ) {
      setOpenNotify(false);
    }
  };
  const handleClickNotify = () => {
    setOpenNotify(!openNotify);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <>
      <HeaderAuth
        open={openNotify}
        handleClick={handleClickNotify}
        setshowSideBar={setshowSideBar}
        shodeSideBarBtn={true}
      />
      <NavbarAuth showSideBar={showSideBar} />
      {/* sidebar with links */}
      <section className="custom-padding-afterLogin" ref={newRef}>
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-12 col-lg-12 col-xl-2 sidebar-afterLogin-wrapper ${showSideBar ? "show" : ""
                }`}
            >
              <div className="sidebar-afterLogin">
                <Link
                  className="sidebar-closeBtn"
                  onClick={() => setshowSideBar(false)}
                >
                  <img width={32} className="" src={close} alt="close" />
                </Link>

                <ul
                  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                  id="menu"
                >
                  <li
                    className={`nav-item ${pathname === "/profile" && "active"
                      }`}
                  >
                    <Link
                      to="/profile"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My Profile</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname.split("/").includes("property") && "active"
                      } 
                      
                    }`}
                  >
                    <Link
                      to="/property"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My Properties</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname.split("/").includes("business-service") && "active"
                      }`}
                  >
                    <Link
                      to="/business-service"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My Businesses & Services</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname.split("/").includes("advert") && "active"
                      }`}
                  >
                    <Link
                      to="/advert"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My Adverts</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname.split("/").includes("payments") && "active"
                      } `}
                  >
                    <Link
                      to="/payments"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My Payments</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname.split("/").includes("invoice") && "active"
                      } `}
                  >
                    <Link
                      to="/invoice"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My Invoice</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname === "/chat" && "active"}`}
                  >
                    <Link
                      to="/chat"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">Property Chat</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${(pathname === "/analytics" ||
                      pathname === "/analytics/detail") &&
                      "active"
                      }`}
                  >
                    <Link
                      to="/analytics"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">Analytics</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname === "/recorded-hack" && "active"
                      }`}
                  >
                    <Link
                      to="/recorded-hack"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My Recorded Hack</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname === "/wish-list" && "active"
                      }`}
                  >
                    <Link
                      to="/wish-list"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">Wishlist</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname.split("/").includes("search-history") && "active"
                      } 
                      
                    }`}
                  >
                    <Link
                      to="/search-history"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">Saved Search</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname === "/change-password" && "active"
                      }`}
                  >
                    <Link
                      to="/change-password"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">Change Password</span>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname === "/crm" && "active"
                      }`}
                  >
                    <Link
                      to="/crm"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">My CRM</span>
                    </Link>
                  </li>
                  {/* <li className={`nav-item ${pathname === "/" && "active"}`}>
                      <Link to="#" className="nav-link align-middle px-0">
                        <span className="">
                          Storage (My plans)
                        </span>
                      </Link>
                    </li>
                    <li className={`nav-item ${pathname === "/" && "active"}`}>
                      <Link to="#" className="nav-link align-middle px-0">
                        <span className="">My Coins</span>
                      </Link>
                    </li>
                    <li className={`nav-item ${pathname === "/" && "active"}`}>
                      <Link to="#" className="nav-link align-middle px-0">
                        <span className="">
                          My Video Library
                        </span>
                      </Link>
                    </li>
                    <li className={`nav-item ${pathname === "/" && "active"}`}>
                      <Link to="#" className="nav-link align-middle px-0">
                        <span className="">
                          Start Streaming
                        </span>
                      </Link>
                    </li>
                    <li className={`nav-item ${pathname === "/" && "active"}`}>
                      <Link to="#" className="nav-link align-middle px-0">
                        <span className="">
                          Affiliate Products
                        </span>
                      </Link>
                    </li>
                    <li className={`nav-item ${pathname === "/" && "active"}`}>
                      <Link to="#" className="nav-link align-middle px-0">
                        <span className="">
                          Payment History
                        </span>
                      </Link>
                    </li> */}
                  {/* <li className={`nav-item ${pathname === "/" && "active"}`}>
                      <Link to="#" className="nav-link align-middle px-0">
                        <span className="">Market Transactions</span>
                      </Link>
                    </li> */}

                  <li
                    className={`nav-item`}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <Link
                      // to="#"
                      className="nav-link align-middle px-0"
                      onClick={() => setshowSideBar(false)}
                    >
                      <span className="">Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-12 col-xl-10">
              {/* main content with children props */}
              <TabsContext.Provider value={{ authTabs, dispatchAuthTabs }}>
                {children}
              </TabsContext.Provider>
            </div>
            {<LogoutModal handleLogout={handleLogout} />}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AuthLayout;
