import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import noNotification from "../../../Assets/images/icon/no-notification.png";
import placeholderImg from "../../../Assets/images/image-placeholder.jpg";
import {
  useGetNotificationMutation,
  useReadNotificationMutation,
} from "../../../Redux/Auth/chat";
import { handleLogout } from "../../../Redux/Auth/users";
import { useGetProperyMutation } from "../../../Redux/Global/properties";
import { notificationChat } from "../../../Redux/chatSlice";
import { propertySlug } from "../../../helper";
import LazyImageWithFallback from "../../CommonComponents/Js/LazyImageWithFallback";
import { UserAvtar } from "../../CommonComponents/User-Avtar";
import { getTimeDifference } from "../../Pages/Auth/Chat/chatAssetData";
import LogoutModal from "../../Pages/Auth/LogoutModal";
const HeaderAuth = ({ open, handleClick, setshowSideBar,dispatchAuthTabs , shodeSideBarBtn }) => {
  const users = useSelector((user) => user.user);
  const [loading, setLoading] = useState(false);
  const [notificationParams, setNotificationParams] = useState({
    page: 1,
    limit: 10,
  });
  const [getNoficationlist, { data: notification, isSuccess }] =
    useGetNotificationMutation();
  // const [addNotifi]
  const [getProperty] = useGetProperyMutation();
  const [readNotification] = useReadNotificationMutation();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const notificationChats = useSelector((state) => state?.chat?.getNofication);
  const selectPropertyDetails = useSelector(
    (state) => state?.chat?.selectPropertyDetails
  );
  useEffect(() => {
    getNoficationlist(notificationParams).then((res) => {
      if (res?.data?.type === "success") {
        dispatch(notificationChat(res?.data?.data));
      }
    });
  }, [notificationParams]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const scrolling = () => {
    if (
      Math.abs(
        scrollRef?.current?.scrollHeight -
          scrollRef?.current?.scrollTop -
          scrollRef?.current?.clientHeight
      ) < 1 &&
      notificationParams?.page < notificationChats?.page
    ) {
      setLoading(true);
      setNotificationParams(() => {
        return {
          page: notificationParams?.page + 1,
          limit: notificationParams?.limit,
        };
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  const getDay = (date) => {
    const currentDay = new Date().getDate();
    const day = new Date(date).getDate();
    if (currentDay === day) {
      return "Today";
    } else if (currentDay === day - 1) {
      return "Yesterday";
    } else {
      return getTimeDifference(date);
    }
  };
  const groupping = (currentInd) => {
    let notification = notificationChats?.notifications.filter((obj, index) => {
      return (
        index ===
        notificationChats?.notifications.findIndex((o) => obj?._id === o?._id)
      );
    });
    if (
      getDay(notification[currentInd - 1]?.createdAt) ===
      getDay(notification[currentInd]?.createdAt)
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (notificationChats?.notifications?.length < 1 && isSuccess) {
      getNoficationlist(notificationParams).then((res) => {
        if (res?.data?.type === "success") {
          dispatch(notificationChat(res?.data?.data));
        }
      });
    }
  }, [notificationChats?.notifications?.length]);

  return (
    <main className="afterlogin-header">
      <div className="top-header">
        <nav className="d-flex flex-row-reverse bd-highlight">
          <ul
            className="nav ms-auto me-auto me-sm-0 profile-menu"
            id="notification"
          >
            <li className="notification" id="notification">
              <div
                className="notification-bell"
                id="notification"
                onClick={handleClick}
              >
                <svg
                  id="notification"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 5.33301C12 4.27214 11.5786 3.25473 10.8284 2.50458C10.0783 1.75444 9.06087 1.33301 8 1.33301C6.93913 1.33301 5.92172 1.75444 5.17157 2.50458C4.42143 3.25473 4 4.27214 4 5.33301C4 9.99967 2 11.333 2 11.333H14C14 11.333 12 9.99967 12 5.33301Z"
                    stroke="#F8F2C9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.15432 14C9.03712 14.2021 8.86888 14.3698 8.66647 14.4864C8.46406 14.6029 8.23458 14.6643 8.00099 14.6643C7.7674 14.6643 7.53792 14.6029 7.33551 14.4864C7.13309 14.3698 6.96486 14.2021 6.84766 14"
                    stroke="#F8F2C9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span id="notification" className="badge">
                  {notificationChats?.count > 0 &&
                    (notificationChats?.count > 99
                      ? `99+`
                      : notificationChats?.count)}
                </span>
              </div>
              <div
                className={`notification-panel ${open && "active"}`}
                id="notification"
                onScroll={scrolling}
                ref={scrollRef}
              >
                <div className="read-all-wrapper" id="notification">
                  {notificationChats?.count > 0 && (
                    <button
                      className="btn btn-xs btn-purple read-all-btn"
                      id="notification"
                      onClick={() =>
                        readNotification(
                          JSON.stringify({ readAll: true })
                        ).then((res) => {
                          if (res?.data?.type === "success") {
                            dispatch(
                              notificationChat({ notifications: [], count: 0 })
                            );
                            getNoficationlist();
                          }
                        })
                      }
                    >
                      Mark all as read
                    </button>
                  )}
                </div>
                {notificationChats?.notifications?.length ? (
                  <>
                    {notificationChats?.notifications?.map((notifi, ind) => (
                      <div
                        className="notification-panel-body"
                        id="notification"
                        key={ind}
                      >
                        <div
                          className="d-flex align-items-center justify-content-end"
                          id="notification"
                        >
                          <p
                            className="notification-date"
                            style={{ margin: "5px" }}
                            id="notification"
                          >
                            {groupping(ind) && getDay(notifi?.createdAt)}
                          </p>
                        </div>
                        {notifi?.type === "message" ? (
                          <div
                            className="notification-item-wrapper"
                            id="notification"
                            onClick={() => {
                              readNotification({
                                notification: notifi?._id,
                              }).then((res) => {
                                if (res?.data?.type === "success") {
                                  if (
                                    (selectPropertyDetails?.userId !==
                                      notifi?.additionalData?.sender?._id &&
                                      selectPropertyDetails?.propertyId !==
                                        notifi?.additionalData?.property
                                          ?._id) ||
                                    pathname !== "/chat"
                                  ) {
                                    navigate("/chat", {
                                      state: {
                                        id: notifi?.additionalData?.property,
                                        sender: notifi?.additionalData?.sender,
                                      },
                                    });
                                  }
                                  dispatch(
                                    notificationChat({
                                      flag: true,
                                      notifications: [
                                        ...notificationChats?.notifications?.slice(
                                          0,
                                          ind
                                        ),
                                        ...notificationChats?.notifications?.slice(
                                          ind + 1,
                                          notificationChats?.notifications
                                            ?.length
                                        ),
                                      ],
                                      pageInfo: {
                                        totalPages: notificationChats?.page,
                                      },
                                      count: notificationChats?.count - 1,
                                    })
                                  );
                                }
                              });
                            }}
                          >
                            <div
                              className="notification-item"
                              id="notification"
                            >
                              <div
                                className="notification-thumb"
                                id="notification"
                              >
                                {notifi?.additionalData?.sender?.profileImage
                                  ?.url ? (
                                  <img
                                    className=""
                                    src={
                                      notifi?.additionalData?.sender
                                        ?.profileImage?.url
                                    }
                                    alt="Notification Img"
                                    id="notification"
                                  />
                                ) : (
                                  // <span className="px-2">
                                  <UserAvtar
                                    username={notifi?.additionalData?.sender?.name}
                                    height="42px"
                    name={"border border-white  border-2 mx-1"}

                                  />
                                  // </span>
                                )}
                              </div>
                              <div
                                className="notification-content"
                                id="notification"
                              >
                                <p
                                  className="notification-text"
                                  id="notification"
                                >
                                  {notifi?.message}
                                  <span style={{ float: "right" }}>
                                    {getTimeDifference(notifi?.createdAt)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          notifi?.type === "property" && (
                            <div
                              className="notification-item-wrapper"
                              id="notification"
                              onClick={() => {
                                readNotification(
                                  JSON.stringify({
                                    notification: notifi?._id,
                                  })
                                ).then((res) => {
                                  if (res?.data?.type === "success") {
                                    dispatch(
                                      notificationChat({
                                        flag: true,
                                        notifications: [
                                          ...notificationChats?.notifications?.slice(
                                            0,
                                            ind
                                          ),
                                          ...notificationChats?.notifications?.slice(
                                            ind + 1,
                                            notificationChats?.notifications
                                              ?.length
                                          ),
                                        ],
                                        pageInfo: {
                                          totalPages: notificationChats?.page,
                                        },
                                        count: notificationChats?.count - 1,
                                      })
                                    );
                                  }
                                });
                                getProperty(
                                  notifi?.additionalData?.property?._id
                                ).then((res) => {
                                  if (
                                    res?.data?.data?.status === "rejected" &&
                                    !res?.data?.data?.isArchived
                                  ) {
                                    navigate(
                                      `/property/edit/${notifi?.additionalData?.property?._id}`
                                    );
                                  } else if (
                                    !res?.data?.data?.isArchived &&
                                    res?.data?.data?.isLive
                                  ) {
                                    navigate(
                                      propertySlug(
                                        notifi?.additionalData?.property?.type,
                                        notifi?.additionalData?.property
                                          ?.addressMeta,
                                        notifi?.additionalData?.property
                                          ?.slug ??
                                          notifi?.additionalData?.property?._id
                                      )
                                    );
                                  } else {
                                    if (res?.data?.data?.isDeleted) {
                                      toast.error("This property is deleted", {
                                        position: toast.POSITION.TOP_RIGHT,
                                      });
                                      navigate("/property");
                                    } else if (res?.data?.data?.isArchived) {
                                      toast.error("This property is archived", {
                                        position: toast.POSITION.TOP_RIGHT,
                                      });
                                      dispatchAuthTabs({type:"property" ,property:"archived"});
                                      navigate("/property");
                                    } else {
                                      toast.info(
                                        "This property in under review",
                                        {
                                          position: toast.POSITION.TOP_RIGHT,
                                        }
                                      );
                                      dispatchAuthTabs({type:"property" ,property:"underReview"});
                                      navigate("/property");
                                    }
                                  }
                                });
                              }}
                            >
                              <div
                                className="notification-item"
                                id="notification"
                              >
                                <div
                                  className="notification-thumb"
                                  id="notification"
                                >
                                  <LazyImageWithFallback
                                    src={
                                      notifi?.additionalData?.property?.image
                                        ?.url
                                        ? notifi?.additionalData?.property
                                            ?.image?.url
                                        : "placeholder"
                                    }
                                    alt={"Notification Img"}
                                    id="notification"
                                    className="notification-img"
                                  />
                                  {/* <img className="" src={notifi?.additionalData?.property?.image?.url} alt="Notification Img" id="notification" /> */}
                                </div>
                                <div
                                  className="notification-content"
                                  id="notification"
                                >
                                  <p
                                    className="notification-text"
                                    id="notification"
                                  >
                                    {notifi?.message}
                                    <span
                                      style={{
                                        float: "right",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      {getTimeDifference(notifi?.createdAt)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="no-notification-wrapper" id="notification">
                      <img
                        className="no-notification"
                        src={noNotification}
                        alt="No Notification"
                        id="notification"
                      />
                      <h5 className="noNotification-text " id="notification">
                        {" "}
                        You currently have no{" "}
                        <br
                          className="d-none d-md-block"
                          id="notification"
                        />{" "}
                        notification!
                      </h5>
                    </div>
                  </>
                )}
                {/* Spinner For data Load Of Notification  */}
                {loading && (
                  <div
                    id="notification"
                    className="loader-wrapper text-center py-3"
                  >
                    <ClipLoader
                      id="notification"
                      className="notification-loader loader"
                      color="#c720db"
                      size={45}
                    />
                  </div>
                )}
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href=" "
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {users?.image ? (
                  <LazyImageWithFallback src={users?.image} alt="logo" />
                ) : (
                  <UserAvtar
                    textColor={"ffffff"}
                    username={users?.name}
                    name={"border border-secondary  border-2 mx-1"}
                  />
                )}
                <span>{users?.name}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 14.5L12 9.5L17 14.5"
                    stroke="white"
                    strokeWidth="1.66667"
                  />
                </svg>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/profile">
                    <i className="fas fa-sliders-h fa-fw"></i> My Profile
                  </Link>
                </li>
                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <Link className="dropdown-item">
                    <span className="">Logout</span>
                  </Link>
                </li>
              </ul>
            </li>

            {shodeSideBarBtn ? (
              <li className="afterlogin-home-btn">
                <button
                  className="btn border-0"
                  onClick={() => setshowSideBar(true)}
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#daa520" className="bi bi-house" viewBox="0 0 16 16">
                  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                </svg> */}
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26 8H12"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M26 16H6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M26 24H6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </nav>
        {<LogoutModal handleLogout={handleLogout} />}
      </div>
    </main>
  );
};

export default HeaderAuth;
