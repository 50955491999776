import React from "react";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const { pathname } = useLocation();
  return (
    <main className="top-header">
      <nav className="py-1 py-sm-2 justify-content-right">
        <div className="container d-flex flex-row-reverse bd-highlight py-1">
          <ul className="nav">
            <li className="nav-item">
              <Link
                className={`nav-link ${pathname === "/sign-up" && "active"}`}
                to="/sign-up"
              >
                SIGN UP
              </Link>
            </li>
            <li className="nav-item">
              <span className="devider"></span>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${pathname === "/login" && "active"}`}
                to="/login"
              >
                LOGIN
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </main>
    
    
  );
};

export default Header;
