import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routers } from "./routers";
import Layouts from "../View/Layouts/Js/Index";
import PageNotFound from "../View/Layouts/Js/ErrorPages/404";
import { AUTH, GLOBAL, OTHER } from "../helper";
import { getAccessToken } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { checkTrack } from "../Redux/userSlice";

const AppRouter = () => {
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const dispatch = useDispatch();
  // isError is called for 500 server error
  // const isError = useSelector((errorCode) => errorCode?.errorCode?.isError);
  const checkTrackStatus = useSelector((user) => user?.user?.trackStatus);
  const navigate = useNavigate();
  function isLogin(permission) {
    if (permission === GLOBAL || permission === OTHER) {
      return true;
    }
    if (permission === AUTH && getAccessToken()) {
      return true;
    }
    return false;
  }
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // useEffect(() => {
  //   if (isError === '/500') {
  //     navigate('/500')
  //   }
  // }, [])
  // 
  const handleConfirmation = () => {
    const result = window.confirm("Are you sure you want to end your ongoing track?");
    if (result) {
      dispatch(checkTrack(false))
      return;
      // Add your logic for the "OK" case here
    } else {
      // Add your logic for the "Cancel" case here
      navigate('/riding');
    }
  };
  // condition to show popup if track is on going from riding page
  if (location.pathname !== '/riding' && checkTrackStatus) {
    handleConfirmation()
  }

  // added below useEffect for managing newly added url for news/blogs detail page
  useEffect(() => {
    if (window.location.pathname?.includes('news-blog/detail/')) {
      navigate(window.location.pathname?.replace('/detail/', '/'))
    }
  }, [window.location.pathname])

  return (
    <Routes>
      {routers.map((router, index) => (
        <React.Fragment key={index}>
          {isLogin(router?.permission) && (
            <>
              {router.children?.length > 0 && (
                <React.Fragment>
                  {router.children.map((route, childIndex) => (
                    <Route
                      key={childIndex}
                      path={router.path + route.path}
                      element={
                        router?.permission === AUTH ? (
                          <>
                            {/* Render the router's component */}
                            {route.getParentComponent && router.component}
                            {route.component}
                          </>
                        ) : router?.permission === OTHER ? (
                          <>
                            {route.getParentComponent && router.component}
                            {route.component}
                          </>
                        ) : (
                          <Layouts>
                            {/* Render the router's component */}
                            {route.getParentComponent && router.component}
                            {route.component}
                          </Layouts>
                        )
                      }
                    />
                  ))}
                </React.Fragment>
              )}
              <Route
                path={router.path}
                element={
                  router?.permission === AUTH ? (
                    <>{router.component}</>
                  ) : router?.permission === OTHER ? (
                    <>{router.component}</>
                  ) : (
                    <Layouts>{router.component}</Layouts>
                  )
                }
              />
            </>
          )}
        </React.Fragment>
      ))}
      {/* Render the PageNotFound component for undefined routes */}
      <Route
        path="*"
        element={
          <Layouts>
            <PageNotFound />
          </Layouts>
        }
      />
    </Routes>
  );
};

export default AppRouter;
