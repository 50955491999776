import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { paginationScroll } from "../../../helper";
const Pagination = ({ pages }) => {
  // State to manage the displayed page numbers and the total number of pages
  const [displayedPages, setDisplayedPages] = useState([]);
  const [totalPages, setTotalPages] = useState(pages?.total ? pages?.total : 0);
  const { pathname } = useLocation();
  // Effect to update the total number of pages when the 'pages' prop changes
  useEffect(() => {
    if (pages && pages.total && pages.current) {
      setTotalPages(pages.total);
    } else {
      setTotalPages(0);
    }
    setTimeout(() => {  
      if (pages?.filter?.page > 1 && (pages?.data?.length === 0 || (!pages?.data && !pages?.total))) {
        previousPage()
      }
    }, 300);
  }, [pages]);
  // Effect to calculate and update the displayed page numbers based on the total number of pages and current page
  useEffect(() => {
    const currentPage = parseInt(pages?.current);
    const maxDisplayPages = 5;
    const pageNumbers = [];

    // Logic to determine which pages to display based on the current page and total pages
    // Generates an array of page numbers to be displayed
    // Pages outside the range are represented by '...' for compactness
    // Adjusts the page numbers based on the current page and total pages

    if (totalPages > maxDisplayPages) {
      if (currentPage <= maxDisplayPages - 2) {
        for (let i = 1; i <= maxDisplayPages - 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - (maxDisplayPages - 2)) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - (maxDisplayPages - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }
    setDisplayedPages(pageNumbers); // Update the displayed page numbers
  }, [totalPages, pages?.current]);

  const nextPage = () => {
    if (parseInt(pages?.filter?.page) < totalPages) {
      pages.setFilter({
        ...pages.filter,
        page: parseInt(pages.filter.page) + 1,
      });
    }
  };

  const previousPage = () => {
    if (parseInt(pages?.filter?.page) > 1) {
      pages.setFilter({
        ...pages.filter,
        page: parseInt(pages.filter.page) - 1,
      });
    }
  };
  return (
    <>
      {totalPages > 1 ? (
        <nav aria-label="Page navigation" className="pagination-wrapper">
          <ul className="pagination custom-pagination">
            <li className="page-item">

              <a
                href={
                  paginationScroll(pathname)
                }
                className="text-decoration-none"
              >  <button
                className="page-link"
                onClick={previousPage}
                disabled={parseInt(pages?.current) === 1}
              >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.16 7.41024L10.58 12.0002L15.16 16.5902L13.75 18.0002L7.75 12.0002L13.75 6.00024L15.16 7.41024Z"
                      fill="#635061"
                    />
                  </svg>
                </button>
              </a>
            </li>
            {/* Displayed Page Numbers */}
            {displayedPages.map((page, index) => (
              <li className="page-item" key={index}>
                {page === "..." ? (
                  <span className="page-link">...</span>
                ) : (
                  <a
                    href={
                      paginationScroll(pathname)
                    }
                    className="text-decoration-none"
                  >
                    <button
                      className={`page-link ${parseInt(pages?.current) === page ? "current" : ""
                        }`}
                      onClick={() => {
                        if (parseInt(pages?.filter?.page) !== page) {
                          pages.setFilter({
                            ...pages.filter,
                            page: page,
                          });
                        }
                      }}
                    >
                      {page}
                    </button>
                  </a>
                )}
              </li>
            ))}
            <li className="page-item">

              <a
                href={
                  paginationScroll(pathname)
                }
                className="text-decoration-none"
              ><button
                className="page-link"
                onClick={nextPage}
                disabled={
                  parseInt(pages?.current) === parseInt(totalPages)
                    ? true
                    : false
                }
              >
                  <span aria-hidden="true">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.84 7.41024L13.42 12.0002L8.84 16.5902L10.25 18.0002L16.25 12.0002L10.25 6.00024L8.84 7.41024Z"
                        fill="#635061"
                      />
                    </svg>
                  </span>{" "}
                </button>
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
