import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken, getRefreshToken } from "../utils";
import { errorCode } from "./errorSlice";
import { authToken, user, userLogout } from "./userSlice";

export const logOut = async (token) => {
  // Implement the logic to log the user out.
  const res = await fetch(`${process.env.REACT_APP_BASEURL}auth/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken: token }),
  });
  // Clear tokens and reload the page after logging out
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window.location.reload();
  return res;
};

// Base query setup with fetchBaseQuery
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURL,
  withCredentials: true,
  credentials: "same-origin",
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 'FETCH_ERROR') {
      api?.dispatch(errorCode({ payload: 500 }));
    }
    // Handle server errors
    api?.dispatch(errorCode({ payload: result?.error?.data?.code }));
    if (
      [401].includes(result?.error?.data?.code) &&
      !extraOptions?.customRefresh &&
      getAccessToken()
    ) {
      // Use refresh token to get a new access token
      const refreshToken = getRefreshToken() || "";
      // Send a refresh token to get a new access token
      const refreshResult = await fetch(
        `${process.env.REACT_APP_BASEURL}/auth/refresh-tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken: refreshToken }),
        }
      );

      const data = await refreshResult.json();
      if (data.type === "success") {
        // Update the access token and user profile if needed
        // const data = refreshResult.data.data;
        localStorage.setItem("accessToken", data?.data?.access?.token);
        localStorage.setItem("refreshToken", data?.data?.refresh?.token);
        api.dispatch(
          authToken({
            accessToken: data?.data?.access?.token,
            refreshToken: data?.data?.refresh?.token,
          })
        );
        const userResult = await fetch(
          `${process.env.REACT_APP_BASEURL}/users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${data?.data?.access?.token}`,
            },
          }
        );
        const userData = await userResult.json();
        if (userData.type === "success") {
          // Dispatch user data to the Redux store
          api.dispatch(
            user({
              id: userData?.data?.id,
              name: userData?.data?.name,
              image: userData?.data?.profileImage,
              email: userData?.data?.email,
              paymentExempt: userData?.data?.paymentExempt
            })
          );
          // Perform the base query again after refreshing the token
          result = await baseQuery(args, api, extraOptions);
        } else {
          // Handle user data fetch failure by logging out
          api.dispatch(userLogout());
          logOut();
          return;
        }
      } else {
        // Handle token refresh failure by logging out
        api.dispatch(userLogout());
        logOut();
        return;
      }
    }
    return result;
  } catch (error) {
    // console.error("Error in baseQueryWithReauth:", error);
    throw error;
  }
};

// Create API instance using createApi from Redux Toolkit
export const serverApi = createApi({
  reducerPath: "serverApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}), // Define endpoints if needed
});
