import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Tooltips = ({ children, msg, direction }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <OverlayTrigger
      placement={direction ? direction : "top"}
      overlay={<Tooltip id="tooltip">{msg}</Tooltip>}
      show={showTooltip}
      delay={{ show: 100, hide: 100 }}
    >
      <span className="d-inline-block"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </span>
    </OverlayTrigger>
  );
};

export default Tooltips;
