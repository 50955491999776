import React from "react";
import { AUTH, GLOBAL, OTHER } from "../helper";
import Advert from "../View/Pages/Auth/bannerAds/Index";
import LookingLocally from "../View/Pages/Category/LookingLocally";

const Home = React.lazy(() => import("../View/Pages/Home/Index"));
const RecommendedProperty = React.lazy(() => import("../View/Pages/recommendedProperty/Index"));
const About = React.lazy(() => import("../View/Pages/About/Index"));
const Properties = React.lazy(() => import("../View/Pages/Properties/Index"));
const SearchHistory = React.lazy(() => import("../View/Pages/Auth/Properties/SearchHistory/Index"));
const ViewProperty = React.lazy(() =>
  import("../View/Pages/Properties/ViewProperty/Index")
);
const Riding = React.lazy(() => import("../View/Pages/Riding/Index"));
// const Shopping = React.lazy(() => import("../View/Pages/Shopping/Index"));
const BusinessServices = React.lazy(() =>
  import("../View/Pages/BusinessServices/Index")
);
const Faq = React.lazy(() => import("../View/Pages/Faq/Index"));
const NewsBlog = React.lazy(() => import("../View/Pages/NewsBlog/Index"));
const Login = React.lazy(() => import("../View/Pages/Login/Index"));
const SignUp = React.lazy(() => import("../View/Pages/SignUp/Index"));
const ContactUs = React.lazy(() => import("../View/Pages/ContactUs/Index"));
const ForgotPassword = React.lazy(() =>
  import("../View/Pages/ForgotPassword/Index")
);
const ResetPassword = React.lazy(() =>
  import("../View/Pages/ResetPassword/Index")
);
const ServerError = React.lazy(() =>
  import("../View/Layouts/Js/ErrorPages/500")
);
// Import Auth components
const AuthLayout = React.lazy(() => import("../View/Pages/Auth/Index"));
const Profile = React.lazy(() => import("../View/Pages/Auth/Profile"));
const Crm = React.lazy(() => import("../View/Pages/Auth/Crm"));
const ChangePassword = React.lazy(() =>
  import("../View/Pages/Auth/ChangePassword")
);
const WishList = React.lazy(() => import("../View/Pages/Auth/WishList"));
const Property = React.lazy(() =>
  import("../View/Pages/Auth/Properties/Index")
);
const PropertyForm = React.lazy(() =>
  import("../View/Pages/Auth/Properties/Form")
);
const Detail = React.lazy(() => import("../View/Pages/NewsBlog/Detail"));
// const PreLoved = React.lazy(() =>
//   import("../View/Pages/Shopping/MarketPlace/Index")
// );
// const PreLovedDetail = React.lazy(() =>
//   import("../View/Pages/Shopping/MarketPlace/Detail")
// );
const Adverts = React.lazy(() => import("../View/Pages/Auth/Adverts/Index"));
const AdvertsForm = React.lazy(() => import("../View/Pages/Auth/Adverts/Form"));
const AdvertForm = React.lazy(() => import("../View/Pages/Auth/bannerAds/Form"));
const Analytics = React.lazy(() =>
  import("../View/Pages/Auth/Analytics/Index")
);
const AnalyticsDetail = React.lazy(() =>
  import("../View/Pages/Auth/Analytics/Detail")
);
const Advertise = React.lazy(() => import("../View/Pages/Advertise/Index"));
const SellerDetail = React.lazy(() => import("../View/Pages/Seller/Index"));
const Chats = React.lazy(() => import("../View/Pages/Auth/Chat/Index"));
const PaymentsList = React.lazy(() =>
  import("../View/Pages/Auth/Payment/List")
);
const Categories = React.lazy(() => import("../View/Pages/Category/Index"));
const InvoiceList = React.lazy(() =>
  import("../View/Pages/Auth/Payment/Invoice/Index")
);
const InvoiceDetail = React.lazy(() =>
  import("../View/Pages/Auth/Payment/Invoice/Detail")
);
const RecordedHack = React.lazy(() =>
  import("../View/Pages/Auth/RecordedHack/Index")
);
const Categorie = React.lazy(() => import("../View/Pages/Category/Categorie"));
const TermsConditions = React.lazy(() => import("../View/Pages/TermsCondition/Index"));
const PrivacyPolicy = React.lazy(() => import("../View/Pages/PrivacyPolicy/Index"));
const Shopping = React.lazy(() => import("../View/Pages/Shopping/Index"));

export const routers = [
  {
    path: "/health-check",
    name: "HealthCheck",
    component: <p>{JSON.stringify({ isHealthy: true })}</p>,
    permission: OTHER,
    children: [],
  },
  {
    path: "/",
    name: "Home",
    component: <Home />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/home",
    name: "Home",
    component: <Home />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/about",
    name: "About",
    component: <About />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/properties",
    name: "Properties",
    component: <Properties />,
    permission: GLOBAL,
    children: [
      {
        path: "",
        name: "properties",
        getParentComponent: false,
        component: <Properties />,
        permission: GLOBAL,
      },
      {
        path: "/:type/:address/:id",
        name: "viewproperty",
        getParentComponent: false,
        component: <ViewProperty />,
        permission: GLOBAL,
      },
    ],
  },
  {
    path: "/riding",
    name: "Riding",
    component: <Riding />,
    permission: GLOBAL,
    children: [],
  },
  //  Shoping page is Cooming soon
  {
    path: "/shopping",
    name: "Shopping",
    component: <Shopping />,
    permission: GLOBAL,
    children: [
      // {
      //   path: "/market-place",
      //   name: "Pre-Loved Market Place",
      //   getParentComponent: false,
      //   component: <PreLoved />,
      //   permission: GLOBAL,
      // },
      // {
      //   path: "/market-place/detail",
      //   name: "Pre-Loved Detail",
      //   getParentComponent: false,
      //   component: <PreLovedDetail />,
      //   permission: GLOBAL,
      // },
    ],
  },
  {
    path: "/businesses-services",
    name: "Businesses & Services",
    component: <BusinessServices />,
    permission: GLOBAL,
    children: [
      {
        path: "/categories",
        name: "Categories",
        getParentComponent: false,
        component: <Categories />,
        permission: GLOBAL,
      },
      {
        path: "/:id",
        name: "Categories",
        getParentComponent: false,
        component: <Categorie />,
        permission: GLOBAL,
      },
      {
        path: "/looking-locally",
        name: "Looking Locally",
        getParentComponent: false,
        component: <LookingLocally />,
        permission: GLOBAL,
      },
    ],
  },

  // {
  // path: "/",
  // name: "Live Tv & Recordings",
  // component: <About />,
  // permission: GLOBAL,
  // children: [],
  // },
  {
    path: "/faq",
    name: "FAQ's",
    component: <Faq />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/news-blog",
    name: "News & Blogs",
    component: <NewsBlog />,
    permission: GLOBAL,
    children: [
      {
        path: "",
        name: "News & Blogs",
        getParentComponent: false,
        component: <NewsBlog />,
        permission: GLOBAL,
      },
      {
        path: "/:id",
        name: "News Details",
        getParentComponent: false,
        component: <Detail />,
        permission: GLOBAL,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: <Login />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: <SignUp />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: <ContactUs />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: <ForgotPassword />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: <ResetPassword />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/500",
    name: "500",
    component: <ServerError />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/advertise-with-us",
    name: "Advertise with us",
    component: <Advertise />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/seller-detail/:id",
    name: "Seller Detail",
    component: <SellerDetail />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/terms-conditions",
    name: "Terms & Conditions",
    component: <TermsConditions />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: <PrivacyPolicy />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/recommended-property/:id",
    name: "Recommended Property",
    component: <RecommendedProperty />,
    permission: GLOBAL,
    children: [],
  },
  {
    path: "/profile",
    name: "My Profile",
    component: (
      <AuthLayout>
        <Profile />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
  {
    path: "/crm",
    name: "CRM",
    component: (
      <AuthLayout>
        <Crm />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
  {
    path: "/wish-list",
    name: "WishList",
    component: (
      <AuthLayout>
        <WishList />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: (
      <AuthLayout>
        <ChangePassword />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
  {
    path: "/recorded-hack",
    name: "Recorded Hack",
    component: (
      <AuthLayout>
        <RecordedHack />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
  {
    path: "/property",
    name: "Property",
    component: (
      <AuthLayout>
        <Property />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [
      {
        path: "",
        name: "Property",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <Property />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      {
        path: "/add",
        name: "Add Property",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <PropertyForm />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      {
        path: "/edit/:id",
        name: "edit Property",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <PropertyForm />
          </AuthLayout>
        ),
        permission: AUTH,
      },
    ],
  },
  {
    path: "/search-history",
    name: "Search History",
    component: (
      <AuthLayout>
        <SearchHistory />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
  {
    // business-service
    path: "/advert",
    name: "Advert",
    component: (
      <AuthLayout>
        <Advert />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [
      {
        path: "",
        name: "Advert",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <Advert />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      {
        path: "/add",
        name: "Add Advert",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <AdvertForm />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      {
        path: "/edit/:id",
        name: "Edit Advert",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <AdvertForm />
          </AuthLayout>
        ),
        permission: AUTH,
      },
    ],
  },
  {
    // business-service
    path: "/business-service",
    name: "Businesses & Services",
    component: (
      <AuthLayout>
        <Adverts />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [
      {
        path: "",
        name: "Businesses & Services",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <Adverts />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      {
        path: "/add",
        name: "Add Businesses & Services",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <AdvertsForm />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      {
        path: "/edit/:id",
        name: "Edit Businesses & Services",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <AdvertsForm />
          </AuthLayout>
        ),
        permission: AUTH,
      },
    ],
  },
  {
    path: "/payments",
    name: "Payments",
    component: (
      <AuthLayout>
        <PaymentsList />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: (
      <AuthLayout>
        <InvoiceList />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [
      {
        path: "",
        name: "Invoice",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <InvoiceList />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      {
        path: "/detail/:id",
        name: "View Invoice",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <InvoiceDetail />
          </AuthLayout>
        ),
        permission: AUTH,
      },
    ],
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: (
      <AuthLayout>
        <Analytics />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [
      {
        path: "",
        name: "Analytics",
        getParentComponent: false,
        component: (
          <AuthLayout>
            <Analytics />
          </AuthLayout>
        ),
        permission: AUTH,
      },
      // {
      //   path: "/detail",
      //   name: "View Analytics",
      //   getParentComponent: false,
      //   component: (
      //     <AuthLayout>
      //       <AnalyticsDetail />
      //     </AuthLayout>
      //   ),
      //   permission: AUTH,
      // },
    ],
  },
  {
    path: "/chat",
    name: "Property/Marketplace Chat",
    component: (
      <AuthLayout>
        <Chats />
      </AuthLayout>
    ),
    permission: AUTH,
    children: [],
  },
];
