import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/scss/bootstrap.scss";
import "react-quill/dist/quill.snow.css"; // Import news & blogs editor the styles
import App from "./App";
import "./Assets/scss/main.scss"; // all imports for scss are included in this file
import reportWebVitals from "./reportWebVitals";
// import '~bootstrap/scss/bootstrap';
// MapComponent.jsx
import 'leaflet/dist/leaflet.css';
import { ToastContainer } from "react-toastify";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <App />
    <ToastContainer />
  </>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
