import { toast } from "react-toastify";

// input select option data
export const searchObj = [
  { value: "product", label: "Product" },
  { value: "service", label: "Service" },
  { value: "banner", label: "Banner" },
  { value: "marketplace", label: "Marketplace" },
];
export const categoriesValues = ["product", "service", "marketplace"];
//  formik data

export const validationSchema = (Yup) => {
  return Yup.object().shape({
    postName: Yup.string()
      .required("Post Name is required")
      .min(5, "Post Name must be at least 5 characters")
      .max(100, "Post Name should be less than or equal to 100 characters")
      .trim(),
    location: Yup.string()
      .required("Location is required")
      .test("not-same-as-old", "Please enter valid Location", function (value) {
        return typeof this.parent.lat == "number";
      }),
    // price: Yup.number()
    //   .min(0, "Please enter valid price")
    //   .integer("Please enter valid price")
    //   .max(10000000000, "Price must not exceed €10000000000")
    //   .required("Price is required"),
    website: Yup.string()
      .required("Website Link is required")
      .matches(
        /* eslint-disable-next-line */
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        // /^((http(s)?:\/\/)?(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?)?$/,
        "Please enter valid website link"
      )
      .max(255, "Website should be less than or equal to 255 characters"),
    message: Yup.string()
      .min(5, "Message must be at least 5 characters")
      .max(500, "Message should be less than or equal to 500 characters")
      .required("Message is required")
      .trim(),
    images: Yup.array()
      .min(1, "Image is required")
      .max(3, "Images should be less than or equal to 3"),
    categories: Yup.array()
      .min(1, "Category is required"),
  });
};

export const initialValues = (id, userId, advertData) => {
  return {
    user: userId,
    postName: id ?? advertData?.isSuccess ? advertData?.data?.data?.name : "",
    location:
      id ?? advertData?.isSuccess ? advertData?.data?.data?.address : "",
    long:
      id ?? advertData?.isSuccess
        ? advertData?.data?.data?.location?.coordinates[0]
        : "",
    lat:
      id ?? advertData?.isSuccess
        ? advertData?.data?.data?.location?.coordinates[1]
        : "",
    // price: id ?? advertData?.isSuccess ? advertData?.data?.data?.price : "",
    website: id ?? advertData?.isSuccess ? advertData?.data?.data?.website : "",
    message:
      id ?? advertData?.isSuccess ? advertData?.data?.data?.description : "",
    images: id && advertData?.isSuccess ? advertData?.data?.data?.images : [],
    deleteImages: [],
    categories:
      id ?? advertData?.isSuccess
        ? advertData?.data?.data?.categories?.map((e) => {
          return {
            value: e?._id,
            label: e?.name,
          };
        })
        : [],
  };
};

export const formValues = (id, values) => {
  var data = new FormData();
  data.append("name", values.postName);
  // data.append("price", `${values.price}`);
  data.append("address", values?.location);
  data.append("location[coordinates][0]", `${values?.long}`);
  data.append("location[coordinates][1]", `${values?.lat}`);
  data.append("website", values?.website);
  data.append("description", values?.message);
  // data.append("category", values?.category);
  for (const file of values?.images) {
    if (file?.constructor === File) {
      data.append("images", file);
    }
  }
  if (values?.categories && !id) {
    values?.categories?.map((cat, i) =>
      data.append(`categories[${i}]`, cat?.value)
    );
  }
  if (id && values?.deleteImages.length > 0) {
    values?.deleteImages?.map((file, i) =>
      data.append(`deleteImages[${i}]`, `${file}`)
    );
  }
  if (!id) {
    data.append("user", values?.user);
  }
  return data;
};

export const responseHandle = (res, navigate, dispatchAuthTabs, resMessage) => {
  if (res?.data?.type === "success") {
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatchAuthTabs({
      type: "businessAndService",
      businessAndService: "live",
    });
    navigate("/business-service");
  }
  if (resMessage) {
    toast.success(resMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/business-service", { state: { tab: "live" } });
  }
  if (res?.error) {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const responseHandleBanner = (
  res,
  navigate,
  dispatchAuthTabs,
  resMessage
) => {
  if (res?.data?.type === "success") {
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatchAuthTabs({ type: "advert", advert: "live" });
    navigate("/advert");
  }
  if (resMessage) {
    toast.success(resMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/advert");
  }
  if (res?.error) {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const responseHandleForPayment = (
  res,
  navigate,
  setFilterData,
  dispatchAuthTabs,
  resMessage
) => {
  if (res?.data?.type === "success") {
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setFilterData((data) => {
      return {
        ...data,
        status: "live",
      };
    });
    dispatchAuthTabs({
      type: "businessAndService",
      businessAndService: "live",
    });
    navigate("/business-service");
  }
  if (resMessage) {
    toast.success(resMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setFilterData((data) => {
      return {
        ...data,
        status: "live",
      };
    });
    dispatchAuthTabs({
      type: "businessAndService",
      businessAndService: "live",
    });
    navigate("/business-service");
  }
  if (res?.error) {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const responseHandleBannerForPayment = (
  res,
  navigate,
  setFilterData,
  dispatchAuthTabs,
  resMessage
) => {
  if (res?.data?.type === "success") {
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setFilterData((data)=>{
      return{
        ...data ,status:"live"
      }
    })
    dispatchAuthTabs({ type: "advert", advert: "live" });
    navigate("/advert");
  }
  if (resMessage) {
    toast.success(resMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/advert");
  }
  if (res?.error) {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
