import { config } from "../config";
import { serverApi } from "../serviceAPI";

const propertiesQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.mutation({
      query: (payload) => {
        let url = '/property/list';
        if (!payload?.search) {
          // remove search key
          delete payload?.search
        }
        return {
          ...config("Post", url, true),
          body: payload,
        };
      },
    }),
    getPropertyFilters: builder.query({
      query: (payload) => {
        return config("Get", `/property/filters`);
      },
    }),
    getPropertie: builder.query({
      query: (payload) => {
        return {
          ...config("Get", `/property/${payload}`, true),
        };
      },
      keepUnusedDataFor: 5
    }),
    getRecommendedProperty: builder.mutation({
      query: (payload) => {
        return {
          ...config("Post", `/property/recommended/${payload?.id}`, true),
          body: payload?.body,
        };
      },
    }),
    getPropery: builder.mutation({
      query: (payload) => {
        return {
          ...config("Get", `/property/${payload}`, true),
        };
      },
    }),
    getSaveSearch: builder.query({
      query: (payload) => {
        if (payload) {
          return {
            ...config("Get", `/property/save-search`, true),
          };
        }
      },
      keepUnusedDataFor: 1
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPropertiesMutation,
  useGetPropertyFiltersQuery,
  useGetPropertieQuery,
  useGetRecommendedPropertyMutation,
  useGetProperyMutation,
  useGetSaveSearchQuery
} = propertiesQuery;
