import React from "react";
import serachProperty from "../../../../Assets/images/search-property.svg";
import { useNavigate } from "react-router-dom";

const NoDataFound = ({ activeTab }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="commanCard-afterlogin h-100">
        <div className="tab-content draft-tab h-100" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="liveproperties"
          >
            <div className="live-property-wrapper">
              <div>
                <img src={serachProperty} alt="Serach property" />
                <h5>Sorry, No Adverts Found</h5>
                <button
                  type="submit"
                  className="btn btn-icon-start btn-icon btn-yellow btncomman-afterlogin"
                  disabled=""
                  onClick={() => navigate("/advert/add")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 5V19"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M5 12H19"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  Create Advert
                </button>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="industry"
            role="tabpanel"
            aria-labelledby="Draft-property"
          >
            ...
          </div>
          <div
            className="tab-pane fade"
            id="Campaigns"
            role="tabpanel"
            aria-labelledby="Archived-property"
          >
            ...
          </div>
          <div
            className="tab-pane fade"
            id="property"
            role="tabpanel"
            aria-labelledby="My-property-search"
          >
            ...
          </div>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;
