import { getAccessToken } from "../utils";

function getHeader(auth) {
  let zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (auth) {
    if (getAccessToken()) {
      return {
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        "user-tz": zone
      };
    }
  } else {
    return {
      "Content-Type": "application/json",
      "user-tz": zone
    };
  }
}
export const config = (method, data, getAuth) => {
  var config = {
    method: method,
    url: data,
    headers: getAuth ? getHeader(getAuth) : getHeader(false),
  };
  return config;
};

export const formConfig = (method, url, data) => {
  var config = {
    method: method,
    url: url,
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      "user-tz": Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    body: data,
  };
  return config;
};
