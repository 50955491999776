import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the module slice
const initialState = {
  searchList: [],
};
// Create the module slice using createSlice
export const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    addSearchList: (state, { payload }) => {
      state.searchList = payload;
    },
  },
});

// Action creators are automatically generated for each case reducer function
// Export the action creators
export const { addSearchList } = moduleSlice.actions;

// Export the module slice reducer
export default moduleSlice.reducer;
