import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import searchlogo from "../../../Assets/images/logo.png"; //search logo Image
import Tooltips from "./Tooltips";
// import makeAnimated from "react-select/animated";
const SearchBar = () => {
  // const animatedComponents = makeAnimated();
  // const [isClearable] = useState(true);
  const [reset, setReset] = useState(false);
  const [isSearchable] = useState(true);
  const [isLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let searchObj = [
    { value: "", label: "Select" },
    { value: "/properties", label: "Properties" },
    { value: "/businesses-services/categories", label: "B&S Categories" },
    { value: "/news-blog", label: "News & Blogs" },
  ];
  const validationSchema = Yup.object({
    // page: Yup.string(),
    // .required("Please select Page."),
    keyword: Yup.string(),
    // .required("Please enter Keyword.")
  });
  const formik = useFormik({
    initialValues: {
      page: location?.state?.values?.page || "",
      keyword: location?.state?.values?.keyword || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values) {
        navigate(values?.page, { state: { values } });
      }
      setReset(() => {
        return true;
      });
    },
  });
  useEffect(() => {
    if (location?.state?.values?.keyword === formik.values?.keyword && location?.state?.values?.page === formik.values.page) {
      setReset(true)
    }
  }, [location.state?.values])
  return (
    /** Code for search bar [used in multiple pages] that includes :
     * horse image
     * content
     * search dropdown
     * input search
     */
    <section className="common-searchbar-wrapper">
      <div className="container">
        <div className="common-search">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-5">
              <div className="searchbar-content-wrapper d-flex align-items-center">
                <div className="searchbar-logo-wrapper">
                  <img src={searchlogo} alt="logo" />
                </div>
                <h5 className="searchbar-text mb-0">
                  What are you <br className="d-none d-lg-block" /> looking to{" "}
                  <span> Discover? </span>{" "}
                </h5>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-7">
              <form onSubmit={formik.handleSubmit}>
                <div className="searchbar-field-wrapper mt-3 mt-lg-0">
                  <Select
                    className="common-select basic-single"
                    classNamePrefix="select"
                    defaultValue={searchObj[0]}
                    isLoading={isLoading}
                    // isClearable={isClearable}
                    isSearchable={isSearchable}
                    name="page"
                    options={searchObj}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("page", selectedOption.value);
                      if (!selectedOption.value) {
                        navigate(location?.pathname, { replace: true });
                      }
                    }}
                    value={searchObj?.find(
                      (option) => option.value === formik.values.page
                    )}
                  />
                  <span className="devider"></span>
                  <input
                    type="text"
                    placeholder="Search"
                    autoComplete="off"
                    name="keyword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.keyword}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        if (!e.shiftKey) {
                          e.preventDefault();
                          formik.handleSubmit();
                        }
                      }
                    }}
                  />
                  {reset && (
                    // <Tooltips msg={`Reset`}>
                      <button
                        className="btn border-0 btn-reset"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.setFieldValue("page", "");
                          formik.setFieldValue("keyword", "");
                          navigate(location.pathname);
                          setReset(false);
                        }}
                      >
                        Reset
                      </button>
                    // </Tooltips>
                  )}
                  <div className="btn-wrapper-search-bar">
                    <Tooltips
                      msg={`Search ${searchObj.find((item) => item.value === formik.values?.page)?.label && searchObj.find((item) => item.value === formik.values?.page)?.label}`}
                    >
                      <button
                        className="btn  border-0 btn-search"
                        type="submit"
                        disabled={
                          formik.values.page && formik.values.keyword
                            ? false
                            : true
                        }
                      >
                        <img width={30} src={searchlogo} alt="search" />
                      </button>
                    </Tooltips>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchBar;
