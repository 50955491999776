import { Modal } from "react-bootstrap";
import CloseBtn from "../../../Assets/images/icon/close.svg";
import { Link, useNavigate } from "react-router-dom";
import Tooltips from "../../CommonComponents/Js/Tooltips";
const DescriptionModal = ({
    advertModalData,
    setShowDescriptionModal,
    bio,
    categoryList,
}) => {
    const navigate = useNavigate();
    if (advertModalData) {
        return (
            advertModalData?.description && (
                <>
                    <Modal.Header className="custom-modalHeader pb-0 border-0 justify-content-between">
                        <h5 className="modal-title">{advertModalData?.name}</h5>
                        <button
                            className="modal-CloseBtn btn p-0"
                            onClick={() => {
                                setShowDescriptionModal(false);
                            }}
                        >
                            <img width={40} src={CloseBtn} alt="Close" />
                        </button>
                    </Modal.Header>
                    <Modal.Body className="custom-modalBody">
                        <p
                            className="txt"
                            dangerouslySetInnerHTML={{
                                __html: advertModalData?.description,
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer className="custom-modalFooter border-0 pt-0">
                        <Link
                            className="btn btn-purple btn-sm m-0"
                            target="_blank"
                            to={advertModalData?.link}
                        >
                            DISCOVER MORE
                        </Link>
                    </Modal.Footer>
                </>
            )
        );
    } else if (categoryList) {
        return (
            <>
                <Modal.Header
                    className="custom-modalHeader pb-3 justify-content-between"
                    style={{ borderBottom: " 1px solid #d1d1d1" }}
                >
                    <h5 className="modal-title ">
                        All Categories ({categoryList?.name})
                    </h5>
                    <button
                        className="modal-CloseBtn btn p-0"
                        onClick={() => {
                            setShowDescriptionModal(false);
                        }}
                    >
                        <img width={40} src={CloseBtn} alt="Close" />
                    </button>
                </Modal.Header>
                <Modal.Body className="custom-modalBody">
                    <div>
                        {categoryList?.list?.map((e) => (
                            <p
                                // className="p-1"
                                style={{
                                    borderBottom: "1px solid #d1d1d1",
                                }}
                                onClick={() => {
                                    navigate(`/businesses-services/${e?.slug ?? e?.id}`);
                                    setShowDescriptionModal(false);
                                }}
                            >
                                <Tooltips msg={`See more details about ${e?.name}`}>
                                    <p style={{ cursor: "pointer" }}> {e?.name}</p>
                                </Tooltips>
                            </p>
                        ))}
                    </div>
                </Modal.Body>
            </>
        );
    } else if (bio) {
        return (
            <>
                <Modal.Header
                    className="custom-modalHeader pb-3 justify-content-between"
                    style={{ borderBottom: " 1px solid #d1d1d1" }}
                >
                    <h5 className="modal-title ">{bio?.name}</h5>
                    <button
                        className="modal-CloseBtn btn p-0"
                        onClick={() => {
                            setShowDescriptionModal(false);
                        }}
                    >
                        <img width={40} src={CloseBtn} alt="Close" />
                    </button>
                </Modal.Header>
                <Modal.Body className="custom-modalBody">
                    <div>{bio?.description}</div>
                </Modal.Body>
            </>
        );
    }
};
export default DescriptionModal;
