import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import HeaderAuth from "./HeaderAuth";
import HelmetTitle from "../../CommonComponents/Js/helmetTitle";
const Layouts = ({ children }) => {
  const location = useLocation();
  const isLogin = useSelector((user) => user?.user?.isLogin);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [openNotify, setOpenNotify] = useState(false);
  const newRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (
      e?.target?.parentElement?.id !== "notification" ||
      e?.target?.id !== "notification"
    ) {
      setOpenNotify(false);
    }
  };
  const handleClickNotify = () => {
    setOpenNotify(!openNotify);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="min-h-screen" ref={newRef}>
      <HelmetTitle title="" />
      {isLogin ? (
        <HeaderAuth
          open={openNotify}
          handleClick={handleClickNotify}
          shodeSideBarBtn={false}
        />
      ) : (
        <Header />
      )}
      <Navbar />
      <div className="body-bg pb-28 inner-top-shadow">{children}</div>
      <Footer />
    </div>
  );
};

export default Layouts;
