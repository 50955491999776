import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../Redux/Auth/users";
import closeBtn from "../../../Assets/images/icon/close.svg";

const LogoutModal = ({ handleLogout }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [logout] = useLogoutMutation();
    return (
        <div className="modal fade logout-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header justify-content-between  align-items-start">
                        <div className="d-flex align-items-center">
                            <div className="logout-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.08382 20.75H5.19493C4.67923 20.75 4.18466 20.5451 3.82 20.1805C3.45535 19.8158 3.25049 19.3213 3.25049 18.8056V5.19444C3.25049 4.67875 3.45535 4.18417 3.82 3.81951C4.18466 3.45486 4.67923 3.25 5.19493 3.25H9.08382" stroke="#635061" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.8896 16.8614L20.7508 12.0003L15.8896 7.13916" stroke="#635061" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.7507 12H9.08398" stroke="#635061" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div>
                                <h5 className="modal-title" id="exampleModalLabel">Logout</h5>
                                <p>Are you sure want to Logout?</p>
                            </div>
                        </div>
                        <button type="button" className="modal-closeBtn btn p-0" data-bs-dismiss="modal" aria-label="Close">
                            <img className="" src={closeBtn} alt="close" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex justify-content-end">
                            {/* <button className="btn btn-white me-3" data-bs-dismiss="modal" type="button" aria-label="Close">Cancel</button> */}
                            <button type="submit" className="btn btn-yellow btncomman-afterlogin" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleLogout(logout, dispatch, navigate)}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoutModal;
