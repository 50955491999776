// query and mutations for prefix
import { config, formConfig } from "../config";
import { serverApi } from "../serviceAPI";

const bannerAds = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        publicBannerAdsList: builder.mutation({
            query: (payload) => {
                return config("GET", `/banners/public?limit=${payload?.limit ?? 10}&page=${payload?.page ?? 1}&random=true`, true);
            },
        }),
        usersBannerAdsList: builder.query({
            query: (payload) => {
                if (payload?.search !== "" && payload?.search) {
                    return config("GET", `/banners?limit=10&page=${payload?.page}&search=${payload?.search}&status=${payload?.status}`, true);
                } else {
                    return config("GET", `/banners?limit=10&page=${payload?.page}&status=${payload?.status}`, true);
                }
            },
        }),
        bannerAdsDetail: builder.mutation({
            query: (payload) => {
                return config("GET", `/banners/${payload}`, true);
            },
        }),
        createBannerAds: builder.mutation({
            query: (payload) => {
                return formConfig("POST", `/banners`, payload);
            },
        }),
        updateBannerAds: builder.mutation({
            query: (payload) => {
                return formConfig("PATCH", `/banners/${payload?.id}`, payload?.data);
            },
        }),
        deleteBannerAds: builder.mutation({
            query: (payload) => {
                return formConfig("DELETE", `/banners/${payload}`);
            },
        }),
    }),
    overrideExisting: false,
});
export const { useBannerAdsDetailMutation, usePublicBannerAdsListMutation, useDeleteBannerAdsMutation, useCreateBannerAdsMutation, useUsersBannerAdsListQuery, useUpdateBannerAdsMutation } = bannerAds;
