import React from "react";
const CommonBanner = ({ children }) => {
  return (
    <section className="banner-section">
      <div className="container">{children}</div>
    </section>
  );
};

export default CommonBanner;
