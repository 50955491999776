import { AES, enc } from "crypto-js";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
export const MAP_LIBRARIES = ["places", "geometry", "visualization"];
// Componets Pages parmission types
export const GLOBAL = "GLOBAL";
export const AUTH = "AUTH";
export const OTHER = "OTHER";
export const MAX_IMAGE_SIZE = 5; // 5MB
export const MAX_PROPERTY_IMAGE_SIZE = 10; // 10MB
export const MapCardSize = 498; // for view properties page
export const SideBarCardSize = 296; // for view properties page
export const defaultCenter = {
  lat: 51.5074,
  lng: 0.1278,
};
export const defaultCord = {
  lat: 54.807662160773084,
  lng: -2.7232523667169573,
};
export const pathColors = {
  "restricted-byways": "#EB1E37",
  footpaths: "#2DAF0C",
  bridleways: "#50056D",
  byways: "#915A17",
};

export const filterTypes = {
  Outbuildings: "outBuilding",
  Stables: "stable",
  Annex: "annex",
  Manège: "manage",
};
export const filterTypesForEdit = {
  outBuilding: "Outbuildings",
  stable: "Stables",
  annex: "Annex",
  manage: "Manège",
};
export const IMAGE_UPLOAD_TYPE = ["image/jpeg", "image/jpg", "image/png"];

export const advsLimit = (pathname) => {
  switch (pathname) {
    case "faq":
      return 3;
    case "news-blog":
      return 4;
    case "properties":
      return 8;
    case pathname:
      return 8;
    default:
      return 10;
  }
};
export const MAPURL = ({ cordinates }) => {
  if (cordinates?.lat && cordinates?.long) {
    return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${cordinates?.lat},${cordinates?.long}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`; // map url for get loctaion using lat/long
  }
};
const helpers = {
  isUrlValid: function (userInput) {
    var res = userInput.match(
      // eslint-disable-next-line no-useless-escape
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res === null) return "";
    else return userInput;
  },
  // This function will create a image link from video/media url [thumbnail for iframe]
  videoThumbnail: function (mediaUrl) {
    // Extract the video ID from the YouTube link
    const videoIdMatch =
      mediaUrl?.match(
        /* eslint-disable-next-line */
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      ) ||
      mediaUrl?.match(
        /* eslint-disable-next-line */
        /https?:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})/
      );

    if (!videoIdMatch || !videoIdMatch[1]) {
      // If the video ID is not found, return null or handle the error accordingly
      return null;
    }

    const videoId = videoIdMatch[1];

    // Construct the thumbnail image URL
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

    return thumbnailUrl;
  },
  youTubeRegex: function getVideoId(url) {
    /* eslint-disable-next-line */
    let regex =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;
    return regex?.test(url);
  },
  // Create image path url
  assets: function (prefix) {
    if (prefix) {
      // check if image exists or not [if not exits then add image placeholder]
      return process.env.REACT_APP_IMAGE_BASEURL + prefix;
    }
  },
  // Used to split title and head
  getHeading: function (heading, space) {
    if (heading) {
      if (!space) {
        space = 1;
      }
      // Split the input string by spaces
      const words = heading.split(" ");

      // Check if there are at least three spaces
      if (words.length >= space) {
        const firstPart = words.slice(0, space).join(" "); // Join the first three words
        const secondPart = words.slice(space).join(" "); // Join the remaining words
        return (
          <h1 className="big-heading">
            {firstPart} <span>{secondPart}</span>{" "}
          </h1>
        );
      } else {
        // Handle cases where there are not enough spaces
        return (
          <h1 className="big-heading">
            {heading} <span></span>{" "}
          </h1>
        );
      }
    }
  },
  squareFeetToAcres: function (squareFeet) {
    // 1 acre = 43,560 square feet
    // squareFeetk
    const squareFeetPerAcre = 43560;
    return ((squareFeet * 1000) / squareFeetPerAcre).toFixed(2);
  },
  AcresToSquareFeet: function (acres) {
    // 1 acre is equal to 43,560 square feet
    const sqFeetPerAcre = 43560;

    // Convert acres to square feet
    const sqFeet = acres * sqFeetPerAcre;

    // Format the square feet with commas as separators
    const formattedSqFeet = sqFeet.toLocaleString("en-UK");

    return formattedSqFeet;
  },
  swlDetete: async function (msg) {
    return await Swal.fire({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
        // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      } else {
        return false;
      }
    });
  },
  swlArchive: async function (type) {
    return await Swal.fire({
      title: "Are you sure?",
      text: `You want to ${type} this property!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${type} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });
  },
  swlDeleteUser: async function (type) {
    return await Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this account!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, delete it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });
  },
  swlTracks: async function () {
    return await Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this track!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, delete it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });
  },
  // function to get all the coordinates for location [return location array includes long and lat]
  getLocationCoordinates: async function (data) {
    let mapLocation = [];
    if (data?.length) {
      await data?.map((item) => {
        mapLocation = [
          ...mapLocation,
          {
            lat: item?.location?.coordinates[1],
            long: item?.location?.coordinates[0],
            title: item?.location?.type,
          },
        ];
      });
    }
    return mapLocation;
  },
  priceFormat: function (price) {
    return new Intl.NumberFormat("en-UK").format(price);
  },
  myMoment: function (input) {
    const momentObj = moment(input);
    return momentObj;
  },
  // Function that takes a date in any format and returns a formatted date string in "dd/mm/yyyy" format
  formattedDateForSubmit: function (date) {
    const originalDate = new Date(date);
    return originalDate.toLocaleDateString("en-GB");
  },
  // Function that takes a date string in "dd/mm/yyyy" format and converts it to "yyyy-mm-dd" format
  formattedDateInput: function (inputDate) {
    return moment(inputDate, "DD/MM/YYYY").format("YYYY-MM-DD");
  },
  removeNullObj: function (obj) {
    const filteredObj = {};
    for (const key in obj) {
      if (obj[key]) {
        filteredObj[key] = obj[key];
      }
    }
    return filteredObj;
  },
  // Debounce function to delay search
  debounce: function (func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  },
  convertToEmbed: function (url) {
    // Extract video ID from various YouTube URL formats
    var videoID = url?.match(
      /* eslint-disable-next-line */
      /(?:\?v=|\/embed\/|\/\d\/|\/vi?\/|\/v\/|youtu\.be\/)([^\?&"'>]+)/
    );
    if (!videoID) {
      // Extract video ID from the YouTube Shorts URL
      /* eslint-disable-next-line */
      videoID = url.match(/(?:youtube\.com\/shorts\/|youtu.be\/)([\w-]+)/);
    }
    if (videoID) {
      // Construct the embed URL
      const embedURL = `https://www.youtube.com/embed/${videoID[1]}`;
      return embedURL;
    } else {
      return "Invalid YouTube URL";
    }
  },
  // Function to validate uploaded images
  validateImages: function (event) {
    // If event.target.files is not defined or empty, return false
    if (!event.target.files || event.target.files.length === 0) {
      return false;
    }
    // Loop through each file in the event
    for (const file of event.target.files) {
      // Check if the file type is not within the accepted image formats
      if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
        // If any file doesn't match the accepted formats, return false
        return false;
      }
    }

    // If all files match the accepted formats, return true
    return true;
  },
  // formatDate100YearsBefore: function (isFromik) {
  //   const today = new Date();
  //   const currentYear = today.getFullYear();
  //   const year100Ago = currentYear - 100;
  //   const date100YearsAgo = new Date(
  //     year100Ago,
  //     today.getMonth(),
  //     isFromik ? today.getDate() - 1 : today.getDate() // Subtract 1 day to get the date 1 day before 100 years ago for Fromik validation
  //   );

  //   const formattedDate = `${date100YearsAgo.getFullYear()}-${date100YearsAgo.getMonth() + 1
  //     }-${date100YearsAgo.getDate()}`;
  //   // 1924-1-24
  //   return formattedDate;
  // },
  formatDate100YearsBefore: function (isFromik) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const year100Ago = currentYear - 100;

    // Create a new date with the year 100 years ago
    const date100YearsAgo = new Date(
      year100Ago,
      today.getMonth(),
      today.getDate()
    );

    // Subtract 1 day if it's for Fromik validation
    if (isFromik) {
      date100YearsAgo.setDate(date100YearsAgo.getDate() - 1);
    }

    // Format the date
    const formattedDate = `${date100YearsAgo.getFullYear()}-${date100YearsAgo.getMonth() + 1 < 10 ? "0" : ""
      }${date100YearsAgo.getMonth() + 1}-${date100YearsAgo.getDate() < 10 ? "0" : ""}${date100YearsAgo.getDate()}`;

    return formattedDate;
  },

  isUrlValidForIframe: function (userInput) {
    var res = userInput.match(
      /* eslint-disable-next-line */
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return "";
    else return userInput;
  },
  paginationScroll: function (page) {
    switch (page) {
      case "/property":
        return "#topToScroll";
      case "/recorded-hack":
        return "#trackData";
      case "/properties":
        return "#sorting_details";
      case "/business-service":
        return "#Businesses&Services";
      case "/wish-list":
        return "#WishlistTop";
      case "/payments":
        return "#paymentsTop";
      case "/invoice":
        return "#invoiceTop";
      case "/analytics":
        return "#analyticsTop";
      case "/advert":
        return "#advertsTop";
      case "/search-history":
        return "#savedSearchTop";
      default:
        break;
    }
  },
  hidePlaceTypes: function () {
    const styles = [
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.school",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.medical",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }],
      },
      // Add more styles for hiding hospitals, apartments, etc. based on their featureType
    ];
    return styles;
  },
  encryption: function (rawData) {
    // let data = JSON.stringify(rawData);
    // const cipherText = AES.encrypt(data, 'enckey');
    // return cipherText.toString();
    return rawData;
  },
  decryption: function (rawData) {
    // if (!!rawData) {
    //   let bytes = AES.decrypt(rawData, 'enckey');
    //   const decrypted = bytes.toString(enc.Utf8);
    //   if (!!decrypted) {
    //     return JSON.parse(decrypted);
    //   }
    // } else {
    //   return rawData;
    // }
    return rawData;
  },
};
// function for showing range marks below slider
export const sliderMarks = (
  tenStep,
  afterTen,
  afterTwelve,
  afterFifteen,
  afterThirty,
  minValue,
  maxValue
) => {
  let arr = [];
  for (
    let i = minValue;
    i <= maxValue;
    i =
    i <= 50 && i >= 40
      ? i + afterFifteen
      : i >= 30 && i <= 40
        ? i + afterThirty
        : i >= 15 && i <= 25
          ? i + afterFifteen
          : i >= 12 && i <= 15
            ? i + afterTwelve
            : i >= 10 && i <= 12
              ? i + afterTen
              : i + tenStep
  ) {
    arr?.push(
      <span key={i} className="scale_item">
        {i}
      </span>
    );
  }
  return arr;
};

// funtion for getting range value like 1K , 1M , 1B
export const reminderOfRange = (range) => {
  if (range < 1e3) return range;
  if (range >= 1e3 && range < 1e6) return +(range / 1e3).toFixed(1) + "K";
  if (range >= 1e6 && range < 1e9) return +(range / 1e6).toFixed(1) + "M";
  if (range >= 1e9 && range < 1e12) return +(range / 1e9).toFixed(1) + "B";
  if (range >= 1e12) return +(range / 1e12).toFixed(1) + "T";
};

export const handleCopyClick = (textToCopyRef, text) => {
  const textToCopy = text;
  // Check if the input has a value before attempting to copy
  if (textToCopy) {
    // Create a temporary textarea element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select the text in the textarea
    textArea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textArea);

    // Provide feedback to the user that the text has been copied
    toast.info("URL copied to clipboard!", {
      position: toast.POSITION.TOP_RIGHT,
      // autoClose: 5
    });
  }
};

export const isValidVideoLink = (url) => {
  // https://video-links.b-cdn.net/media/videolinks/video/haida.MP4
  // Regular expression to match common video hosting platforms
  /* eslint-disable-next-line */
  const CommonVideoUrlRegex = /\.(mp4|avi|mov|mkv)$/i;
  // Test if the URL matches the regex
  return CommonVideoUrlRegex.test(url);
};
// check the url is working or not
export const isWorking = async (url) => {
  try {
    let response = await fetch(url, { method: "HEAD" });
    return response;
  } catch (error) {
    return error;
  }
};

export const liveLocation = () => {
  // If geolocation is available in the browser, attempt to get the current position
  return new Promise((resolve, reject) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        resolve,
        function (position) {
          const coords = position.coords;
          resolve(coords); // Resolve the promise with coordinates
        },
        function (error) {
          reject(error); // Reject the promise with an error if geolocation fails
        }
      );
    }
  });
};
export const showFirst20Words = (inputString) => {
  // Split the input string into words
  const words = inputString.split(/\s+/);
  if (words?.length === 1) {
    // Using a regular expression to match alphabetical letters
    /* eslint-disable-next-line */
    const letterCount = (inputString?.match(/[a-zA-Z]/g) || []).length;
    if (letterCount > 20) {
      // If the count is greater than 15, return the first 15 letters
      return inputString.slice(0, 20) + "...";
    }
  }
  // Extract the first 20 words
  const first20Words = words.slice(0, 15);

  // Join the first 20 words back into a string
  const result = first20Words.join(" ");

  return result;
};
export function truncateString(inputString, maxLength = 30) {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.slice(0, maxLength) + "...";
  }
}
// update class for font side add boostrap class
export function replaceQuillClasses(inputHTML) {
  if (typeof inputHTML !== "string") {
    return inputHTML;
  }
  const classMap = {
    "ql-size-large": "ql-size-large fs-3",
    "ql-size-huge": "ql-size-huge fs-1",
    "ql-size-small": "ql-size-small small",
    "ql-syntax": "ql-syntax bg-dark text-light p-3",
    "ql-align-center": "ql-align-center text-center",
    "ql-align-left": "ql-align-left text-left",
    "ql-align-right": "ql-align-right text-end",
    "ql-align-justify": "ql-align-justify text-justify",
    "ql-indent": "pr",
    "<blockquote":
      "<blockquote style='border-left: 4px solid #007bff; padding: 0 1em; margin: 0;'",
  };

  // Replace each key with its corresponding value in the HTML content
  const replacedHTML = inputHTML.replace(
    /* eslint-disable-next-line */
    /\bql-size-large|<blockquote|ql-indent|ql-size-huge|ql-size-small|ql-syntax|ql-align-center|ql-align-left|ql-align-right|ql-align-justify\b/g,
    (match) => classMap[match]
  );

  return replacedHTML;
}
// function to create property slug
export function propertySlug(type, addressMeta, slug) {
  // /properties/for-sale/fareham/test-4-count
  if (type && addressMeta && slug) {
    // Replace spaces with hyphens and convert to lowercase : toLowerCase().replace(/\s+/g, '-')
    if (addressMeta?.county || addressMeta?.town) {
      if (addressMeta?.county === 'uk' && addressMeta?.town) {
        return `/properties/for-${type}/${addressMeta?.town?.toLowerCase().replace(/\s+/g, '-')}/${slug}`;
      }
      return `/properties/for-${type}/${addressMeta?.county?.toLowerCase().replace(/\s+/g, '-') ?? addressMeta?.town?.toLowerCase().replace(/\s+/g, '-')}/${slug}`;
    }
  } else {
    return "";
  }
}
// function to convert miles decimal
export function truncMiles(miles) {
  if (miles) {
    if (miles < 1) {
      return miles;
    }
    // Truncate the decimal part
    // return Math.trunc(miles);
    return Math.round(miles);
  }
}
export const {
  isUrlValid,
  videoThumbnail,
  youTubeRegex,
  assets,
  getHeading,
  squareFeetToAcres,
  AcresToSquareFeet,
  swlDetete,
  swlArchive,
  swlDeleteUser,
  swlTracks,
  getLocationCoordinates,
  priceFormat,
  myMoment,
  removeNullObj,
  debounce,
  convertToEmbed,
  validateImages,
  formattedDateInput,
  formatDate100YearsBefore,
  formattedDateForSubmit,
  isUrlValidForIframe,
  paginationScroll,
  hidePlaceTypes,
  encryption,
  decryption,
} = helpers;
