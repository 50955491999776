import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FacebookIcon from "../../../Assets/images/icon/facebook.png";
import LinkedinIcon from "../../../Assets/images/icon/linkedin.png";
import TiktokIcon from "../../../Assets/images/icon/tiktok.png";
import TwitterIcon from "../../../Assets/images/icon/twitter.png";
import YoutubeIcon from "../../../Assets/images/icon/youtube.png";
import logo from "../../../Assets/images/logo.png"; //logo Image
import { useGetPagesDataQuery } from "../../../Redux/Global/global";
import ComingSoonModal from "../../CommonComponents/Js/ComingSoonModal";
import Skeleton from "../../CommonComponents/Js/Skeleton";
import Tooltips from "../../CommonComponents/Js/Tooltips";

const Footer = () => {
  const { data, isLoading, isSuccess } = useGetPagesDataQuery("footer");
  // const { data: appData, isSuccess: appSuccess } = useGetPagesDataQuery("app");
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  return (
    <footer className="footer">
      <ComingSoonModal show={show} setShow={setShow} />
      {isLoading && (
        <>
          <Skeleton
            counter={1}
            className="row gx-lg-5 align-items-center align-items-lg-start"
            height={220}
          />
        </>
      )}
      {isSuccess && (
        <div className="container">
          <div className="row gx-lg-5 align-items-center align-items-lg-start">
            <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
              <div className="author-wrapper d-flex align-items-center">
                <img
                  className="logo"
                  src={logo}
                  alt="logo"
                  style={{ width: "79px" }}
                />
                <h6 className="title">
                  Discover <br className="d-none d-md-block" /> Equestrian{" "}
                </h6>
              </div>
              <p className="primary-text">About Discover Equestrian</p>
              <p className="comp-tag-text">{data?.data?.description1}</p>
              <p className="copyright-text">
                Copyright 2024 &copy; discoverequestrian. All rights reserved
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-5 mt-md-0">
              <div className="btn-block">
                <p className="text">{data?.data?.description2}</p>
                {/* {appSuccess && (
                  <div className="d-flex align-items-center">
                    <div className="app-btn-wrapper">
                      <Link className="app-btn d-block" target="_blank" to={appData?.data?.ios}>
                        <LazyImageWithFallback src={appstore} alt="App store" />
                      </Link>
                      <Link className="app-btn" target="_blank" to={appData?.data?.android}>
                        <LazyImageWithFallback
                          src={googleplay}
                          alt="Googleplay store"
                        />
                      </Link>
                    </div>
                    <div className="qr-wrapper">
                      <Link className="qr-btn btn" to={'https://discover-equestrian.com/'}>
                        <LazyImageWithFallback
                          src={footerQr}
                          alt="Googleplay store"
                        />
                      </Link>
                    </div>
                  </div>
                )} */}
                <div className="advertiseWithUs-btn-wrapper">
                  <Link
                    className="advertiseWithUs-btn"
                    to={"/advertise-with-us"}
                  >
                    Advertise with us
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.33209 0.802983C8.0392 0.51009 7.56432 0.51009 7.27143 0.802983C6.97853 1.09588 6.97853 1.57075 7.27143 1.86364L10.6391 5.23127L0.757779 5.12879C0.343588 5.1245 0.00433683 5.45678 4.1184e-05 5.87098C-0.00425446 6.28517 0.328032 6.62442 0.742223 6.62871L10.676 6.73174L7.27143 10.1363C6.97853 10.4292 6.97853 10.9041 7.27143 11.197C7.56432 11.4899 8.0392 11.4899 8.33209 11.197L12.9857 6.54339C13.1272 6.40858 13.2162 6.21894 13.2184 6.00807C13.2191 5.94114 13.211 5.87618 13.1952 5.81427C13.163 5.688 13.0976 5.56847 12.9988 5.46965L8.33209 0.802983Z"
                        fill="#2E252D"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-5 mt-lg-0">
              <div className="contact-block">
                <p className="primary-text">Email</p>
                <Link
                  className="link"
                  to={`mailto:${data?.data?.email}`}
                >
                  {data?.data?.email}
                </Link>

                {/* <p className="primary-text">Phone Number</p>
            <Link className="link" to="tel:+918969695857">+91 8969695857</Link> */}

                <p className="primary-text">Address</p>
                <p className="link">{data?.data?.address}</p>

                <div>
                  <Link className="contact-btn mb-0 " to="/contact-us">
                    Contact Us{" "}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.9223 3.57733C13.5968 3.25189 13.0692 3.25189 12.7438 3.57733C12.4183 3.90277 12.4183 4.4304 12.7438 4.75584L17.1545 9.1666H0.833303C0.373066 9.1666 -3.05176e-05 9.53969 -3.05176e-05 9.99993C-3.05176e-05 10.4602 0.373066 10.8333 0.833303 10.8333H17.1545L12.7438 15.244C12.4183 15.5694 12.4183 16.0971 12.7438 16.4225C13.0692 16.7479 13.5968 16.7479 13.9223 16.4225L19.7423 10.6025C19.9011 10.4507 20 10.2369 20 9.99993C20 9.76321 19.9013 9.54954 19.7428 9.39784L13.9223 3.57733Z"
                        fill=""
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="footer-nav flex-wrap d-flex align-items-center justify-content-center justify-content-xxl-center">
                <ul className="nav nav-pills mb-4 mb-xxl-4">
                  <li className="nav-item">
                    <Link
                      to="/home"
                      className={`nav-link ${(pathname === "/" || pathname === "/home") && "active"
                        }`}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/about" && "active"
                        }`}
                      to="/about"
                    >
                      About us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/properties" && "active"
                        }`}
                      to="/properties"
                    >
                      Properties
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/riding" && "active"
                        }`}
                      to="/riding"
                    >
                      Riding
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/shopping" && "active"
                        }`}
                      to="/shopping"
                    // to="#"
                    // onClick={() => setShow(true)}
                    >
                      Shopping
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/businesses-services" && "active"
                        }`}
                      to="/businesses-services"
                    >
                      Businesses & Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/news-blog" && "active"
                        }`}
                      to="/news-blog"
                    >
                      News & Blogs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/live-recordings" && "active"
                        }`}
                      to="#"
                      onClick={() => setShow(true)}
                    >
                      Live Tv & Recordings
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/faq" && "active"}`}
                      to="/faq"
                    >
                      FAQ's
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/terms-conditions" && "active"}`}
                      to="/terms-conditions"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${pathname === "/privacy-policy" && "active"}`}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>

                <div className="social-media-btn-wrapper gap-2 d-flex">
                  <Tooltips msg={`Facebook`}>
                    <Link
                      className="social-media-btn"
                      target="_blank"
                      to={data?.data?.facebookLink}
                    >
                      <img src={FacebookIcon} alt="Facebook" />
                    </Link>
                  </Tooltips>
                  <Tooltips msg={`TikTok`}>
                    <Link
                      target="_blank"
                      className="social-media-btn"
                      to={data?.data?.tiktokLink}
                    >
                      <img src={TiktokIcon} alt="TikTok" />
                    </Link>
                  </Tooltips>
                  <Tooltips msg={`Twitter`}>
                    <Link
                      target="_blank"
                      className="social-media-btn"
                      to={data?.data?.twitterLink}
                    >
                      <img src={TwitterIcon} alt="Twitter" />
                    </Link>
                  </Tooltips>
                  <Tooltips msg={` LinkedIn`}>
                    <Link
                      target="_blank"
                      className="social-media-btn"
                      to={data?.data?.linkedinLink}
                    >
                      <img src={LinkedinIcon} alt="LinkedIn" />
                    </Link>
                  </Tooltips>
                  <Tooltips msg={`Youtube`}>
                    <Link
                      target="_blank"
                      className="social-media-btn"
                      to={data?.data?.videoLink}
                    >
                      <img src={YoutubeIcon} alt="Youtube" />
                    </Link>
                  </Tooltips>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
