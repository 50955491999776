import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import horseIcon from "../../../../Assets/images/icon/Horse.svg";
import wifiIcon from "../../../../Assets/images/icon/card-wifi.svg";
import defaultCard from "../../../../Assets/images/icon/defaultCard.png";
import discoverCard from "../../../../Assets/images/icon/discoverCard.png";
import MasterCard from "../../../../Assets/images/icon/masterCard.png";
import VisaCard from "../../../../Assets/images/icon/visaCard.png";
import {
  useGetChargesQuery,
  usePaymentPropertyMutation,
  useReApprovePropertyMutation,
  useUpdatePropertyMutation,
} from "../../../../Redux/Auth/property";
import { useCardsListQuery } from "../../../../Redux/Auth/users";
import { responseHandle, responseHandleBanner, responseHandleBannerForPayment, responseHandleForPayment } from "../Adverts/formData";
import { response, responseForPayment } from "../Properties/formData";
import { TabsContext } from "../Index";
const useOptions = () => {
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: "25px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Roboto, Source Code Pro, monospace, SFUIDisplay",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};
const PaymentForm = ({
  propertyId,
  setModalShow,
  resMessage,
  refetch,
  bannerId,
  flag,
  advertId,
  price,
  invoiceId,
  setFilterData,
  invoicePayment,
  dataForm,
  rejectedFlag,
  setRejectModalShow,
  countOfBd
}) => {
  const { dispatchAuthTabs } = useContext(TabsContext);
  const [cardSave, setCardSave] = useState(false);
  const navigate = useNavigate();
  const { data: cardLists, isSuccess: cardsAvailable } = useCardsListQuery();
  const { data: charges } = useGetChargesQuery();
  const [updateProperty] = useUpdatePropertyMutation();
  const [reApprove] = useReApprovePropertyMutation();
  const [sendPayment] = usePaymentPropertyMutation();
  const [searchObj, setSearchObj] = useState([
    { value: -1, label: "Add New Card" },
  ]);
  const [loadding, setLoadding] = useState(false);
  const [card, setCard] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const userName = useSelector((state) => state?.user?.name);
  const [invalidObj, setInvalidObj] = useState({
    cardInvalid: {},
    dateInvalid: {},
    cvvInvalid: {},
  });
  const handleCardNumberChange = (event) => {
    setInvalidObj({ ...invalidObj, cardInvalid: event });
  };
  const handleCardExpiryChange = (event) => {
    setInvalidObj({ ...invalidObj, dateInvalid: event });
  };
  const handleCardCvvChange = (event) => {
    setInvalidObj({ ...invalidObj, cvvInvalid: event });
  };
  useEffect(() => {
    if (cardLists?.data?.length) {
      let data = cardLists?.data?.map((e, ind) => {
        return { value: ind, label: `**** ***** **** ${e?.number} ` };
      });
      if (data?.length) {
        setSearchObj([...searchObj, ...data]);
      }
    }
  }, [cardLists?.data, cardsAvailable]);
  const responseInvoice = (res) => {
    if (res?.data?.type === "success") {
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/invoice");
      dispatchAuthTabs({type:"invoice" ,invoice:propertyId?"property":advertId ? "advert":"banner"})
    }
    if (res?.error) {
      toast.error(res?.error?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleSucess = (res) => {
    if (res?.data?.type === "success") {
      if (!flag) {
        refetch();
      }
      if (propertyId && !invoicePayment) {
        if (setFilterData) {
          responseForPayment(
            res,
            toast,
            navigate,
            setFilterData,
            dispatchAuthTabs,
            resMessage
          );

        } else {
          response(res, toast, navigate, dispatchAuthTabs, resMessage);
        }
      } else if (advertId && !invoicePayment) {
        if (setFilterData) {
          responseHandleForPayment(
            res,
            navigate,
            setFilterData,
            dispatchAuthTabs,
            resMessage
          );
        } else {
          responseHandle(res, navigate, dispatchAuthTabs, resMessage);
        }
      } else if (bannerId && !invoicePayment) {
        if (setFilterData) {
          responseHandleBannerForPayment(
            res,
            navigate,
            setFilterData,
            dispatchAuthTabs,
            resMessage
          );
        } else {
          responseHandleBanner(res, navigate, dispatchAuthTabs, resMessage);
        }

      } else if (invoicePayment) {
        responseInvoice(res);
      }
      Swal.fire("Payment", res?.data?.message, "success");
      setModalShow(false);
    } else {
      Swal.fire(
        "Payment failed",
        "Card is not valid please try again",
        "error"
      );
      setModalShow(false);
    }
  };

  const handleError = (error) => {
    if (!flag) {
      refetch();
    }
    if (propertyId && !invoicePayment) {
      if (setFilterData) {
        responseForPayment(
          error,
          toast,
          navigate,
          setFilterData,
          dispatchAuthTabs,
          resMessage
        );
      } else {
        response(error, toast, navigate, dispatchAuthTabs, resMessage);
      }
    } else if (advertId && !invoicePayment) {
      if (setFilterData) {
        responseHandleForPayment(
          error,
          navigate,
          setFilterData,
          dispatchAuthTabs,
          resMessage
        );
      } else {
        responseHandle(error, navigate, dispatchAuthTabs, resMessage);
      }
    } else if (bannerId && !invoicePayment) {
      responseHandleBanner(error, navigate, resMessage);
    } else if (invoicePayment) {
      responseInvoice(error);
    }
    if (propertyId && !invoicePayment) {
      if (setFilterData) {
        setFilterData((data) => {
          return {
            ...data,
            type: "payment-remaining",
          };
        });
        dispatchAuthTabs({
          type: "property",
          property: "payment-remaining",
        });
      } else {
        dispatchAuthTabs({
          type: "property",
          property: "payment-remaining",
        });
      }
      navigate("/property");
    } else if (advertId && !invoicePayment) {
      if (setFilterData) {
        setFilterData((data) => {
          return {
            ...data,
            status: "draft",
          };
        });
        dispatchAuthTabs({
          type: "businessAndService",
          businessAndService: "draft",
        });
      } else {
        dispatchAuthTabs({
          type: "businessAndService",
          businessAndService: "draft",
        });
      }
      navigate("/business-service");
    } else if (bannerId && !invoicePayment) {
      if (setFilterData) {
        setFilterData((data) => {
          return {
            ...data,
            status: "draft",
          };
        });
        dispatchAuthTabs({
          type: "advert",
          advert: "draft",
        });
      } else {
        dispatchAuthTabs({
          type: "advert",
          advert: "draft",
        });
      }
      navigate("/advert");
    } else {
      Swal.fire(
        "Payment failed",
        "Card is not valid please try again",
        "error"
      );
    }
    setModalShow(false);
  };
  const apiCallForPayment = (paymentProp) => {
    sendPayment(paymentProp)
      .then(async (res) => {
        if (res?.error) {
          handleError(res?.error);
        }
        if (rejectedFlag && dataForm) {
          let ress = await updateProperty({ id: propertyId, data: dataForm });
          toast.success(ress?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          reApprove(propertyId).then((res) => {
            if (res?.data?.type === "success") {
              Swal.fire("Send For Re-Approval", res?.data?.message, "success");
              if (refetch) {
                refetch();
              } else {
                navigate("/property");
              }
              setModalShow(false);
            } else {
              navigate("/property");
              setModalShow(false);
              Swal.fire(
                "Send For Re-Approval",
                res?.error?.data?.message,
                "error"
              );
            }
          });
          setRejectModalShow(false);
        } else if (rejectedFlag) {
          reApprove(propertyId).then((res) => {
            if (res?.data?.type === "success") {
              Swal.fire("Send For Re-Approval", res?.data?.message, "success");
              if (refetch) {
                refetch();
              } else {
                navigate("/property");
              }
              setModalShow(false);
            } else {
              navigate("/property");
              setModalShow(false);
              Swal.fire(
                "Send For Re-Approval",
                res?.error?.data?.message,
                "error"
              );
            }
          });
          setRejectModalShow(false);
        }
        handleSucess(res);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const handleBannerAdsPayment = (token) => {
    if (!token) {
      let paymentProp = {
        bannerId: bannerId,
        cardId: card?.card,
      };
      apiCallForPayment(paymentProp);
    } else {
      let paymentProp = {
        bannerId: bannerId,
        save: cardSave,
        token: token.id,
      };
      apiCallForPayment(paymentProp);
    }
  };
  const handlePropertyPayment = (token) => {
    if (!token) {
      let paymentProp = {
        propertyId: propertyId,
        cardId: card?.card,
      };
      apiCallForPayment(paymentProp);
    } else {
      let paymentProp = {
        propertyId: propertyId,
        save: cardSave,
        token: token.id,
      };
      apiCallForPayment(paymentProp);
    }
  };
  const handleInvoicePayment = (token) => {
    if (!token) {
      let paymentProp = {
        cardId: card?.card,
      };
      if (propertyId) {
        paymentProp = {
          ...paymentProp,
          propertyId: propertyId,
          invoicePayment: true,
        };
      }
      if (advertId) {
        paymentProp = {
          ...paymentProp,
          advertId: advertId,
          invoiceId: invoiceId,
        };
      }
      if (bannerId) {
        paymentProp = {
          ...paymentProp,
          bannerId: bannerId,
          invoiceId: invoiceId,
        };
      }
      apiCallForPayment(paymentProp);
    } else {
      let paymentProp = {
        save: cardSave,
        token: token.id,
        invoicePayment: true,
      };
      if (propertyId) {
        paymentProp = {
          ...paymentProp,
          propertyId: propertyId,
          invoicePayment: true,
        };
      }
      if (advertId) {
        paymentProp = {
          ...paymentProp,
          advertId: advertId,
          invoiceId: invoiceId,
        };
      }
      if (bannerId) {
        paymentProp = {
          ...paymentProp,
          bannerId: bannerId,
          invoiceId: invoiceId,
        };
      }
      apiCallForPayment(paymentProp);
    }
  };
  const handleAdvertPayment = (token) => {
    if (!token) {
      let paymentProp = {
        advertId: advertId,
        cardId: card?.card,
      };
      apiCallForPayment(paymentProp);
    } else {
      let paymentProp = {
        advertId: advertId,
        save: cardSave,
        token: token.id,
      };
      apiCallForPayment(paymentProp);
    }
  };

  const handleSubmit = async () => {
    try {
      if (card?.id) {
        if (propertyId && !invoicePayment) {
          handlePropertyPayment();
        } else if (advertId && !invoicePayment) {
          handleAdvertPayment();
        } else if (bannerId && !invoicePayment) {
          handleBannerAdsPayment();
        } else if (invoicePayment) {
          handleInvoicePayment();
        }
      } else {
        const cardNumberElement = elements?.getElement(CardNumberElement);
        const { token } = await stripe.createToken(cardNumberElement);
        if (propertyId && !invoicePayment) {
          handlePropertyPayment(token);
        } else if (advertId && !invoicePayment) {
          handleAdvertPayment(token);
        } else if (bannerId && !invoicePayment) {
          handleBannerAdsPayment(token);
        } else if (invoicePayment) {
          handleInvoicePayment(token);
        }
      }
    } catch (error) {
      handleError(error);
    }
    setLoadding(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Chrome requires the returnValue to be set
      event.returnValue = "";
      // Your confirmation message to show when refreshing
      const confirmationMessage = "Are you sure you want to refresh?";
      // Display the confirmation message
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <div className={`payment-modal-content ${loadding && "pe-none"}`}>
        <div className="payment-modal-top">
          <p className="price">
            <span>£ </span>
            {price
              ? price
              : propertyId
                ? charges?.data?.value?.property
                : bannerId
                  ? charges?.data?.value?.banner
                  : (charges?.data?.value?.advert * countOfBd)}
          </p>
          {propertyId ? (
            <p className="text">One Time Charges For Property Add</p>
          ) : bannerId ? (
            <p className="text">Charges For Adverts Add</p>
          ) : (
            <p className="text">Charges For Businesses & Services Add</p>
          )}

          <div className="e-group payment-method-wrapper">
            <label>Select Your payment Method</label>
            <Select
              className="basic-single sortBy-dropdown"
              classNamePrefix="select"
              defaultValue={{ value: -1, label: "Add New Card" }}
              placeholder="Select Card"
              name="color"
              options={searchObj}
              onChange={(e) => setCard(cardLists?.data[e?.value])}
            />
          </div>
        </div>
        {!card?._id ? (
          <div className="payment-modal-bottom pt-0">
            <div className="form-group mb-4">
              <label htmlFor="card_num">Card Number</label>
              <div className="card-number-field">
                <CardNumberElement
                  options={options}
                  className="form-control"
                  onChange={handleCardNumberChange}
                />
                {!invalidObj?.cardInvalid?.complete &&
                  !invalidObj?.cardInvalid?.empty && (
                    <span className="text-danger">
                      {invalidObj?.cardInvalid?.error?.message}
                    </span>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="exp">Exp</label>
                  <CardExpiryElement
                    options={options}
                    className="form-control"
                    onChange={handleCardExpiryChange}
                  />
                  {!invalidObj?.dateInvalid?.complete &&
                    !invalidObj?.dateInvalid?.empty && (
                      <span className="text-danger">
                        {invalidObj?.dateInvalid?.error?.message}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="cvv">CVV</label>
                  <CardCvcElement
                    options={options}
                    className="form-control"
                    onChange={handleCardCvvChange}
                  />
                  {!invalidObj?.cvvInvalid?.complete &&
                    !invalidObj?.cvvInvalid?.empty && (
                      <span className="text-danger">
                        {invalidObj?.cvvInvalid?.error?.message}
                      </span>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="payment-card-wrapper">
            <div className="payment-card">
              <div className="img-group-wrapper">
                <img
                  className="card-logo"
                  src={
                    card?.type === "MasterCard"
                      ? MasterCard
                      : card?.type === "Visa"
                        ? VisaCard
                        : card?.type === "Discover"
                          ? discoverCard
                          : defaultCard
                  }
                  alt="payment-card"
                />
                <img className="horse-logo" src={horseIcon} alt="Horse Logo" />
                <img className="wifi-logo" src={wifiIcon} alt="Wifi" />
              </div>
              <h5 className="card-number">XXXX XXXX XXXX {card?.number}</h5>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <h5 className="card-holder-name">{userName}</h5>
                <h4 className="cvv">{card?.expiryDate}</h4>
              </div>
            </div>
          </div>
        )}

        <div className="payment-modal-bottom py-0">
          <div className="col-12 col-md-12">
            <div className="checkbox-wrapper form-group d-flex align-items-center">
              {!card?._id && (
                <>
                  <input
                    id="save_card"
                    type="checkbox"
                    className="form-check"
                    defaultValue={cardSave}
                    onChange={(e) => setCardSave(e.target.checked)}
                  />
                  <label htmlFor="save_card" className="checkbox-label mb-0">
                    Want to save card?
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer justify-content-end border-0">
        {!loadding ? (
          <button
            className="btn btn-lg m-0 btn-yellow"
            onClick={(e) => {
              setLoadding(true);
              handleSubmit(e);
              let timerInterval;
              Swal.fire({
                title: "Payment in progress",
                html: "Close When Payment Process Complete.",
                timer: 2000,
                timerProgressBar: true,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                  timerInterval = setInterval(() => { }, 400);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                }
              });
            }}
            disabled={
              ((!invalidObj?.cardInvalid?.complete ||
                !invalidObj?.cvvInvalid?.complete ||
                !invalidObj?.dateInvalid?.complete) &&
                !card?.card) ||
                loadding
                ? true
                : false
            }
          >
            {rejectedFlag
              ? dataForm
                ? "Pay, Update and Send for Approval"
                : "Pay and Send for Approval "
              : "Pay"}
          </button>
        ) : (
          <div>
            <PulseLoader color="#b511ca" />
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentForm;
