import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import chatSlice from "./chatSlice";
import { serverApi } from "./serviceAPI";
import userSlice from "./userSlice";
import errorSlice from "./errorSlice";
import moduleSlice from "./moduleSlice";

const appReducer = combineReducers({
  [serverApi.reducerPath]: serverApi.reducer,
  user: userSlice,
  chat: chatSlice,
  module:moduleSlice,
  errorCode: errorSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/userLogout') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }, getDefaultMiddleware).concat(serverApi.middleware),
});
setupListeners(store.dispatch);