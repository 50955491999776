import LazyImageWithFallback from "../Js/LazyImageWithFallback";

export const UserAvtar = ({ username, name, fontSize, height }) => {
  const {  character } = RandomColorGenerator(username);
  return (
    character ? <div
      style={{
        backgroundColor: "gray",
        display: "inline-flex",
        overflow: "hidden",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "9999px",
        width: height ?? "2.5rem",
        height: height ?? "2.5rem",
        cursor: "pointer",
      }}
      className={name}
    >
      <span
        className=""
        style={{
          fontWeight: 500,
          fontSize: fontSize ?? "",
          color: "white",
        }}
      >
        {character}
      </span>
    </div>:
   <LazyImageWithFallback
   className="w-25"
   src={"placeholder"}
   alt="profile"
 />
  );
};
export const RandomColorGenerator = (name) => {
  var nameArray = name;
  if (name?.toLowerCase()?.split(" ")?.length > 1 && name?.includes(" ")) {
    nameArray = name?.toLowerCase()?.split(" ");
  }
  return {
    character:
      nameArray?.[0]?.[0]?.toUpperCase() + nameArray?.[1]?.[0]?.toUpperCase(),
  };
};
