export function getTimeDifference(timestamp) {
    const currentTime = new Date().getTime();
    const messageTime = new Date(timestamp).getTime();
    const timeDifference = currentTime - messageTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
        return 'Just now';
    } else if (minutes === 1) {
        return '1 minute ago';
    } else if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (hours === 1) {
        return '1 hour ago';
    } else if (hours < 24) {
        return `${hours} hours ago`;
    } else if (days === 1) {
        return 'Yesterday';
    } else {
        const messageDate = new Date(timestamp);
        let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options = { timeZone: zone, year: 'numeric', month: 'short', day: 'numeric' };
        return messageDate.toLocaleDateString(options);
    }
}
export function convertBytes(bytes) {
    if (bytes === 0) return '0 KB';
    const k = 1024;
    const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return i > 0 ? parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i - 1] : convertBytesForSmall(bytes)
}
function convertBytesForSmall(bytes) {
    const k = 1024;
    const sizeInKB = parseFloat((bytes / k).toFixed(2));
    return sizeInKB + ' KB';
}
export const timeFormat = (messageDate) => {
    let date = new Date(messageDate)
    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return date?.toLocaleTimeString('en-GB', { timeZone: zone, hour: '2-digit', hour12: true, minute: "2-digit" }).toUpperCase() + "  " + date?.toLocaleDateString('en-GB', { timeZone: zone, year: "numeric", day: "2-digit", month: "short" })
}
export const timeFormatForTracks = (messageDate) => {
    let date = new Date(messageDate)
    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return date?.toLocaleDateString('en-GB', { timeZone: zone, year: "numeric", day: "2-digit", month: "short" }) + "  " + date?.toLocaleTimeString('en-GB', { timeZone: zone, hour: '2-digit', hour12: true, minute: "2-digit" }).toUpperCase()
}
export const getMessageFormat = (message, propertyId, attachment, receiver) => {
    let data = {
        type: "property",
        messageType: "message",
        property: propertyId,
        receiver: receiver,
    }
    if (attachment?.length && message) {
        return { ...data, message: message, attachments: attachment }
    }
    else if (attachment?.length) {
        return { ...data, attachments: attachment }
    } else if (message !== "") {
        return { ...data, message: message }
    } else {
        return { flag: true }
    }
}
export const checkFileSize = (file) => {
    for (let i = 0; i < file?.length; i++) {
        // Here Defined File Size Ex : 5 MB
        if (file[i]?.size < 0 || file[i]?.size > 5 * 1024 * 1024) {
            return false;
        }
    }
    return true;
}
export const chatProps = (property) => {
    return {
        properyTitle: property?.title,
        propertyAddress: property?.address,
        propertyId: property?._id,
        userId: property?.user?._id,
        userName: property?.user?.name,
        userContact: property?.user?.contact,
        userProfileImage: property?.user?.profileImage,
        unreadMessagesCount: 0,
        lastMessageTime: new Date(),
        propertyImage: property?.images[0]?.url,
    }
}
export const chatPropsForNotification = (property, sender) => {
    return {
        properyTitle: property?.title,
        propertyAddress: property?.address,
        propertyId: property?._id,
        userId: sender?._id,
        userName: sender?.name,
        userContact: sender?.contact,
        userProfileImage: sender?.profileImage?.url,
        unreadMessagesCount: 0,
        lastMessageTime: new Date(),
        propertyImage: property?.images[0]?.url,
    }
}