import { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Context from "./Context/Context";
import { store } from "./Redux/store";
import AppRouter from "./Routes/AppRouter";
import Loading from "./View/CommonComponents/Js/Loading.js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <DndProvider backend={HTML5Backend}>
          <Provider store={store} >
            <Context />
            <AppRouter />
          </Provider>
        </DndProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
