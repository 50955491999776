// query and mutations for AUth user APIs
import { config } from "../config";
import { formConfig } from "../config";
import { serverApi } from "../serviceAPI";

const advertsQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    advert: builder.mutation({
      query: (payload) => {
        return config("GET", `/adverts/${payload}`, true);
      },
    }),

    adverts: builder.query({
      query: (payload) => {
        let url = `/adverts?page=${payload?.page}&status=${payload?.status}`;
        if (payload?.category && payload?.search) {
          url = `/adverts?page=${payload?.page}&category=${payload?.category}&status=${payload?.status}&search=${payload?.search}`;
        } else if (payload?.category) {
          url = `/adverts?page=${payload?.page}&category=${payload?.category}&status=${payload?.status}`;
        } else if (payload?.search) {
          url = `/adverts?page=${payload?.page}&status=${payload?.status}&search=${payload?.search}`;
        }
        return config("GET", url, true);
      },
    }),
    advertUser: builder.mutation({
      query: (payload) => {
        let url = `/adverts/user/${payload?.id}?page=${payload?.page}`;
        if (payload?.category) {
          url = `/adverts/user/${payload?.id}?category=${payload?.category}&page=${payload?.page}`;
        }
        return config("GET", url, true);
      },
    }),
    publicAdvert: builder.mutation({
      query: (payload) => {
        return config("GET", `/adverts/${payload}`);
      },
    }),
    addAdverts: builder.mutation({
      query: (payload) => {
        return formConfig("Post", "/adverts", payload);
      },
    }),
    updateAdverts: builder.mutation({
      query: (payload) => {
        return formConfig("PATCH", `/adverts/${payload?.id}`, payload?.data);
      },
    }),
    deleteAdvert: builder.mutation({
      query: (payload) => {
        return config("Delete", `/adverts/${payload}`, true);
      },
    }),
    getAllLocallyAdverts: builder.mutation({
      query: (payload) => {
        return config(
          "GET",
          `/adverts/public?limit=${payload?.limit ?? 9}&page=${payload?.page ?? 1}&locally=${true}&userLocation[0]=${payload?.["userLocation[0]"]}&userLocation[1]=${payload?.["userLocation[1]"]}`,
          true
        );
      },
    }),
    getAdvertsCount: builder.query({
      query: () => {
        return config("GET", '/adverts/count');
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAdvertsQuery,
  useAddAdvertsMutation,
  useAdvertMutation,
  useUpdateAdvertsMutation,
  useDeleteAdvertMutation,
  useAdvertUserMutation,
  usePublicAdvertMutation,
  useGetAllLocallyAdvertsMutation,
  useGetAdvertsCountQuery,
} = advertsQuery;
