import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import LazyImageWithFallback from '../Js/LazyImageWithFallback';

export default function SingleCardCarousel({ ImageArray }) {
  return (
    <div className='container inner-slider'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        // effect={'fade'}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper h-100"
      >
        {ImageArray?.map((img ,ind) =>
          <SwiperSlide key={ind}>
            <LazyImageWithFallback
              src={
                img?.url
                  ? img?.url
                  : "placeholder"
              }
              alt={"advertimg" + img?.key}
              className="carousel-img advertisement-img"
            />
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
}


