import { createSlice } from "@reduxjs/toolkit";
// Define the initial state of the error slice
const initialState = {
  isError: "",
};

// Create the error slice using createSlice
export const errorSlice = createSlice({
  name: "errorCode",
  initialState,
  reducers: {
    // Reducer for handling 500 server error
    errorCode: (state, { payload }) => {
      if (payload?.payload === 500) {
        state.isError = "/500";
      } else if (payload?.payload === 401) {
        // Need to manage refresh token issue [if expired then]
        state.isError = "/home";
      } else {
        state.isError = "";
      }
    },
  },
});

// Action creators are automatically generated for each case reducer function

// Export the action creators
export const { errorCode } = errorSlice.actions;

// Export the error slice reducer
export default errorSlice.reducer;
