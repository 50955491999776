import React, { useEffect } from "react";
import CommonBanner from "../../../CommonComponents/Js/Banner";
import SearchBar from "../../../CommonComponents/Js/SearchBar";
import fourzerofour from "../../../../Assets/images/404-img.png";
import sadface from "../../../../Assets/images/sad-face.png";
import { useLocation, useNavigate } from "react-router-dom";
import { routers } from "../../../../Routes/routers";
import { AUTH } from "../../../../helper";

const PageNotFound = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Function to extract a simplified path for comparison
  const childernPathMatch = (path) => {
    return path.split("/")[1] + (path.split("/")[2] || ""); // Handling cases where there might not be a second level
  };

  useEffect(() => {
    // Check if the current route matches any top-level routes requiring authentication
    routers.forEach((router) => {
      if (router.permission === AUTH && router.path === pathname) {
        navigate("/login");
      }

      // Check if the current route matches any child routes requiring authentication
      if (router.permission === AUTH && router.children?.length > 0) {
        router.children.forEach((route) => {
          if (
            childernPathMatch(router.path + route.path) ===
            childernPathMatch(pathname)
          ) {
            navigate("/login");
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CommonBanner />
      <SearchBar />
      {/* code for 404 main content with back to homepage button */}
      <section className="custom-padding bg-yellow-gradient">
        <div className="container">
          <div className="fourzero-main-wrapper">
            <img className="fourzero-img" src={fourzerofour} alt="404" />
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="somthingLost-txt">
                  <h5>
                    <span>Opps!</span> <img src={sadface} alt="sad face" />
                  </h5>
                  <p>Something Lost!</p>
                </div>
              </div>
              <div className="col-12 col-md-1 d-flex">
                <span className="bordervertical-fourzero"></span>
                <span className="bordervertical-fourzero"></span>
              </div>
              <div className="col-12 col-md-5">
                <p className="notfound-txt">
                  The page
                  <br />
                  you’re looking
                  <br /> for is not found
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center btn-backhome">
              <a href="/home" className="btn btn-yellow">
                Back to Homepage
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
