import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the user slice
const initialState = {
  isLogin: false, // Indicates if the user is logged in
  accessToken: "", // User's access token
  refreshToken: "", // User's refresh token
  id: "", // User's ID
  name: "", // User's name
  image: "", // User's profile image
  trackStatus: false, // ongoing track status
  email: "", // User's email
  paymentExempt: "", // User's paymentExempt
};

// Create the user slice using createSlice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Reducer for updating the authentication tokens
    authToken: (state, { payload }) => {
      state.isLogin = true;
      state.accessToken = payload?.accessToken;
      state.refreshToken = payload?.refreshToken;
    },
    // Reducer for updating user information
    user: (state, { payload }) => {
      state.isLogin = true;
      state.id = payload?.id;
      state.name = payload?.name;
      state.image = payload?.image;
      state.email = payload?.email;
      state.paymentExempt = payload?.paymentExempt;
    },

    // Reducer for logging out the user
    userLogout: (state) => {
      // Reset all user-related state properties
      Object.assign(state, initialState);
    },
    // Reducer for managing track status
    checkTrack: (state, { payload }) => {
      state.trackStatus = payload;
    },
  },
});

// Action creators are automatically generated for each case reducer function

// Export the action creators
export const { authToken, user, userLogout, checkTrack } = userSlice.actions;

// Export the user slice reducer
export default userSlice.reducer;
