// query and mutations for login/logout/register APIs
import { config } from "../config";
import { serverApi } from "../serviceAPI";

const auth = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => {
        return { ...config("Post", "/auth/login"), body: payload };
      },
    }),
    register: builder.mutation({
      query: (payload) => {
        return { ...config("Post", "/auth/register"), body: payload };
      },
    }),
    forgot: builder.mutation({
      query: (payload) => {
        return { ...config("Post", "/auth/forgot-password"), body: payload };
      },
    }),
    reset: builder.mutation({
      query: (payload) => {
        return { ...config("Post", `/auth/reset-password${payload?.search}`), body: { password: payload?.values } };
      },
    }),
    refresh: builder.mutation({
      query: (payload) => {
        return {
          ...config("Post", "/auth/refresh-tokens", false),
          body: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotMutation,
  useResetMutation,
  useRefreshMutation,
} = auth;
