// query and mutations for Property Chat APIs
import { getAccessToken } from "../../utils";
import { config } from "../config";
import { serverApi } from "../serviceAPI";

const chatQuery = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        //  getChatList Api For List of Chat For Each Type Chat Property  ,MarketPlace ,Group 
        getChatUsers: builder.mutation({
            query: (payload) => {
                if (payload?.search) {
                    return config("GET", `chat/list?limit=${payload?.limit}&page=${payload?.page}&search=${payload?.search}&type=${payload?.type}`, true);
                } else {
                    return config("GET", `chat/list?limit=${payload?.limit}&page=${payload?.page}&type=${payload?.type}`, true);
                }
            },
        }),
        // getChat Api For Perticular unique Chat
        getChat: builder.query({
            query: (payload) => {
                return config("GET", `/chat?property=${payload?.property}&user=${payload?.user}&limit=${payload?.limit}&page=${payload?.page}&type=${payload?.type}`, true);
            },
        }),
        // sendChat Api For Send Message
        sendChat: builder.mutation({
            query: (payload) => {
                let body = payload?.data
                return {
                    url: process.env.PUBLIC_URL + "chat",
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + getAccessToken(),
                    },
                    body,
                };
            },
        }),
        readChat: builder.mutation({
            query: (payload) => {
                return {
                    ...config("PUT", "/chat/read", true),
                    body: payload,
                };
            },
        }),
        getNotification: builder.mutation({
            query: (payload) => {
                return config("GET", `/notifications?limit=${payload?.limit}&page=${payload?.page}`, true);
            },
        }),
        readNotification: builder.mutation({
            query: (payload) => {
                return {
                    ...config("PUT", "/notifications/read", true),
                    body: payload,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const {
    useGetChatUsersMutation,
    useGetChatQuery,
    useSendChatMutation,
    useReadChatMutation,
    useGetNotificationMutation,
    useReadNotificationMutation
} = chatQuery;
