// query and mutations for AUth user APIs
import { toast } from "react-toastify";
import { config } from "../config";
import { serverApi } from "../serviceAPI";
import { userLogout } from "../userSlice";
import { getAccessToken, getRefreshToken } from "../../utils";
import Cookies from "universal-cookie";

const usersQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    profile: builder.mutation({
      query: () => config("Get", "/users", true),
    }),
    updateProfile: builder.mutation({
      query: (payload) => {
        return { ...config("PATCH", "/users", true), body: payload };
      },
    }),
    resetProfileImage: builder.mutation({
      query: () => {
        return { ...config("PUT", "/users/profile-picture/remove", true) };
      },
    }),
    deleteProfile: builder.mutation({
      query: () => config("DELETE", "/users/delete-account", true),
    }),
    cardsList: builder.query({
      query: () => {
        return config("GET", "/users/cards", true);
      },
    }),
    uploadImage: builder.mutation({
      query: (body) => {
        return {
          url: process.env.PUBLIC_URL + "/users/profile-picture",
          method: "PUT",
          headers: {
            Authorization: "Bearer " + getAccessToken(),
          },
          body,
        };
      },
    }),
    changePassword: builder.mutation({
      query: (payload) => {
        return {
          ...config("PUT", "/users/change-password", true),
          body: payload,
        };
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          ...config("POST", "/auth/logout", true),
          body: { refreshToken: getRefreshToken() },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useProfileMutation,
  useResetProfileImageMutation,
  useCardsListQuery,
  useDeleteProfileMutation,
  useUpdateProfileMutation,
  useUploadImageMutation,
  useChangePasswordMutation,
  useLogoutMutation,
} = usersQuery;

export async function handleLogout(logout, dispatch, navigate) {
  // call logout API
  let cookie = new Cookies()
  let res = await logout();
  if (res?.data?.type === "success") {
    cookie.remove("properties")
    toast.success(res?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    // discard token from local storage
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    dispatch(userLogout());
    // dispatch(logoutUser());
    navigate("/login");

  } else {
    toast.error(res?.error?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
