import React from "react";
import { Modal } from "react-bootstrap";
import bgImg from "../../../Assets/images/comingSoon-bg.jpeg";
import closeBtn from "../../../Assets/images/icon/close-coming-soon.svg";
import { Link } from "react-router-dom";

const ComingSoonModal = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="modal-xl modal-dialog-centered comingSoon-modal"
    >
      <img src={bgImg} alt="" className="bg-img" />
      <Modal.Body>
        <div className="content-block">
          <button className="btn closeBtn" onClick={() => setShow(false)}>
            <img src={closeBtn} alt="" className="" />
          </button>

          <div className="d-flex align-items-center justify-content-center flex-column">
            <p className="discover-txt">Discover Equestrian</p>
            <h4 className="coming-txt">Coming Soon!</h4>
            <h5 className="detail-txt">For more details</h5>
            <Link to="mailto:info@discover-equestrian.com" className="mail-id">
              info@discover-equestrian.com
            </Link>
            <Link to="/home" onClick={() => setShow(false)} className="btn btn-sm btn-yellow">Back To homepage<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.33464 8H12.668" stroke="#2e252d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8.0013 12.6673L12.668 8.00065L8.0013 3.33398" stroke="#2e252d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg></Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ComingSoonModal;
