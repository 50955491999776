import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { useGetChatUsersMutation } from "../Redux/Auth/chat";
import { useProfileMutation } from "../Redux/Auth/users";
import { useRefreshMutation } from "../Redux/Global/auth";
import { addChat, addNotification, addPropertyLists, addSocketModule, propertyLists } from "../Redux/chatSlice";
import { authToken, user, userLogout } from "../Redux/userSlice";
import { debounce } from "../helper";
import { getAccessToken, getRefreshToken } from "../utils";
const Context = () => {
  const [refreshToken] = useRefreshMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [id, setId] = useState(null);
  const { pathname } = useLocation();
  const [getProfile, { data: profileData, isSuccess }] = useProfileMutation();
  const userData = useSelector((state) => state?.user);
  const [getChatList, { data: properties }] = useGetChatUsersMutation();
  const chat = useSelector((state) => state?.chat);
  const dataRef = useRef(chat);
  const countRef = useRef(chat?.getNofication?.count);
  const messageRef = useRef(null);
  useEffect(() => {
    dataRef.current = chat;
    // countRef.current = chat?.getNofication?.count;
  }, [userData, chat])

  // Function to refresh the browser session
  const refreshBrowser = async () => {
    try {
      if (getRefreshToken()) {
        // Attempt to refresh the access token using the refresh token
        const response = await refreshToken({
          refreshToken: getRefreshToken(),
        }).unwrap();
        if (response?.type === "success") {
          // If successful, update the access and refresh tokens in Redux and local storage
          const data = response.data;
          dispatch(
            authToken({
              accessToken: data.access.token,
              refreshToken: data.refresh.token,
            })
          );
          localStorage.setItem("accessToken", data.access.token);
          localStorage.setItem("refreshToken", data.refresh.token);
        } else {
          // If the refresh request is not successful, remove tokens from local storage navigate("/login");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          dispatch(userLogout());
          navigate("/login");
        }
      }
    } catch (error) {
      // Handle errors (e.g., network issues)
    }
  };
  // Call refreshBrowser when the component mounts
  useEffect(() => {
    if (getRefreshToken()) {
      getProfile();
      refreshBrowser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken()]); // This effect runs only once, similar to componentDidMount in class components

  // Update user data in Redux when profile data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      let profile = profileData?.data;
      dispatch(
        user({
          id: profile?.id,
          name: profile?.name,
          image: profile?.profileImage,
          email: profile?.email,
          paymentExempt: profile?.paymentExempt,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (properties?.data) {
      dispatch(
        addPropertyLists({
          data: properties?.data,
          flag: true
        })
      );
    }
  }, [properties]);
  const debouncedGetMessages = useRef(debounce(message => {
    if (
      message?.message?.property === dataRef?.current?.selectPropertyDetails?.propertyId
    ) {
      dispatch(addChat(message));
    } else if (pathname === "/chat") {
      getChatList({
        page: 1,
        limit: 10,
        type: "property",
      }).then(async (res) => {
        await dispatch(propertyLists({
          data: res?.data?.data,
          listParams: { search: "" },
        }))
      });
    }
    return () => {
      messageRef.current = null;
    };
  }, 100)).current;

  const getMessages = useCallback((message) => {
    debouncedGetMessages(message);
  }, [debouncedGetMessages]);


  const handleSocketEvent = useMemo(() => debounce(notification => {
    const timestamp = new Date().getTime(); // Get current time as timestamp
    dispatch(addNotification({
      message: notification?.title,
      // count: countRef.current + 1,
      type: notification?.type,
      _id: notification?.notificationId,
      additionalData: {
        property: {
          _id: notification?.additionalData?.property?._id,
          address: notification?.additionalData?.property?.address,
          status: notification?.additionalData?.property?.status,
          title: notification?.additionalData?.property?.title,
          image: notification?.additionalData?.property?.image,
        },
        sender: {
          _id: notification?.additionalData?.sender?._id,
          name: notification?.additionalData?.sender?.name,
          profileImage: notification?.additionalData?.sender?.profileImage,
          contact: notification?.additionalData?.sender?.contact,
        }
      },
      createdAt: `${new Date(timestamp)}`
    }
    ));
  }, 300), [countRef.current])
  useMemo(() => {
    if (userData?.id) {
      const socket = io(process.env.REACT_APP_SOCKETURL, {
        secure: true,
        autoConnect: true,
        transports: ["websocket"],
        query: {
          userId: userData?.id,
        },
      });
      socket.connect();
      socket.on("message", (message) => {
        messageRef.current = message;
        getMessages(message);
      });
      socket.on("notification", notification => {
        countRef.current = true;
        handleSocketEvent(notification)
      });
      dispatch(addSocketModule(socket))
      setId(userData?.id);

    }
  }, [userData]);
  useEffect(() => {
    if (!getAccessToken()) {
      dispatch(userLogout());
    }
  }, [getAccessToken()]);
  // Return an empty fragment
  return <></>;
};

export default Context;
